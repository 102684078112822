import { useState, useCallback, useEffect } from 'react';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// types
import { IFileShared } from 'src/types/file';
// components
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
// import {  useDeleteUserMutation, useUpdateUserMutation } from "src/context/api/admin/api";

import { useWorkspaceUserDeleteMutation } from 'src/context/api/workspaces';
import { useSnackbar } from 'src/components/snackbar';
import { useLazyGetUserByEmailQuery } from 'src/context/api/admin/api';
import { alpha, useTheme } from '@mui/material/styles';
import { useCurrentUserQuery } from 'src/context/api/auth';

// ----------------------------------------------------------------------

type Props = {
  person: IFileShared;
  workspaceId: number;
  editable?: boolean;
  invitations?: Array<any>;
  onInvitation?: (item: any) => void;
};

export default function FileManagerInvitedQuickEditForm({ person, workspaceId, editable, invitations, onInvitation }: Props) {
  const [permission, setPermission] = useState(person.permission);
  const popover = usePopover();
  const confirmDelete = useBoolean();
  const { t } = useLocales();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [userFind, setUserFind] = useState<{ id: number } | null>(null);
  const [workspaceDeleteUser] = useWorkspaceUserDeleteMutation();
  // const [getUserByEmail, getUserByEmailResponse] = useGetUserByEmailQuery({email:null});
  const [triggerFetch, { data: userData, error, isLoading }] = useLazyGetUserByEmailQuery();
  const user: any = useCurrentUserQuery();

  // Fonction pour extraire le nom de l'image
  const fetchUserByEmail = useCallback(async () => {
    if (person?.email) {
      const email = person?.email;
      await triggerFetch({ email })
        .unwrap()
        .then(response => {
          setUserFind(response);
        })
        .catch(err => {
          enqueueSnackbar('Failed to fetch user', { variant: 'error' });
        });
    }
  }, [person.email, triggerFetch, enqueueSnackbar]);


  const handleDeleteUser = useCallback(async () => {
    confirmDelete.onTrue();
    fetchUserByEmail()
  }, [userFind])

  const handleDeleteRow = useCallback(async () => {
    try {

      if (userFind) {
        const id = userFind?.id;

        await workspaceDeleteUser({ id: workspaceId, userId: id }).unwrap().then(() => {
          enqueueSnackbar(t('dashboard.snackbar.delete_success'));
          confirmDelete.onFalse();
        })
        onInvitation?.({ "workspaceId": workspaceId, "email": person?.email, "toDelete": true })
      }
    } catch (error) {
      enqueueSnackbar(t('dashboard.snackbar.failed_to_delete_user'), { variant: 'error' });
    }

  }, [person, confirmDelete, userFind]);

  return (
    <>
      <ListItem
        sx={{
          px: 0,
          py: 1,
          alignItems: 'center',
        }}
        title={
          person.firstname ?
            person?.firstname + " " + person?.lastname + " - " + person?.email + " - " + person?.role
            : person?.email
        }
      >
        {/* <Avatar alt={person.name} src={person.avatarUrl} sx={{ mr: 2 }} />
         */}
        <Avatar alt={person.firstname + " " + person.lastname}
          sx={{
            mr: 2,
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,

          }} >
          <Typography sx={{ fontSize: '1rem', color: theme.palette.common.white }}>
            {person?.firstname?.charAt(0).toUpperCase()}
            {person?.lastname?.charAt(0).toUpperCase()}
          </Typography>
        </Avatar>

        <ListItemText
          // primary={`${person.firstname} ${person.lastname}`}
          primary={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                {`${person.firstname} ${person.lastname}`}
              </Typography>
              <Typography variant="caption"
                sx={{
                  // flexGrow: 1,
                  // textAlign: 'center',

                  // mr: 2,
                  fontWeight: 'bold',
                  borderRadius: 1,
                  p: 0.5,
                  backgroundColor: theme.palette.mode === 'dark' ? person?.status === 'PENDING' ? '#453E2B' : '#21433A' : person?.status === 'PENDING' ? '#FCEED3' : '#D8F3E2',
                  color: theme.palette.mode === 'dark' ? person?.status === 'PENDING' ? '#FFD666' : '#77ED8B' : person?.status === 'PENDING' ? '#B1741A' : '#118D57',
                }} >
                {person?.status ? person.status.charAt(0) + person.status.slice(1).toLowerCase() : t('dashboard.global.active')}
              </Typography>
            </Stack>
          }
          secondary={
            <Tooltip title={null}>
              <span>{person.email}</span>
            </Tooltip>
          }


          primaryTypographyProps={{ noWrap: true, typography: 'subtitle2' }}
          secondaryTypographyProps={{ noWrap: true, component: 'span' }}
          sx={{ flexGrow: 2, pr: 1 }}
        />

        {
          user?.data?.role === "admin" && (
            <Box sx={{ mr: 1 }}>
              <IconButton color='error' onClick={handleDeleteUser}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Box>

          )
        }

        {
          editable && <Button
            size="small"
            color="inherit"
            endIcon={
              <Iconify
                width={20}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: -1 }}
              />
            }
            onClick={popover.onOpen}
            sx={{
              flexShrink: 0,
              ...(popover.open && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            Can {permission}
          </Button>
        }

      </ListItem>
      <ConfirmDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        title="Delete"
        content="Are you sure want to delete this user?"
        action={
          <Button variant="contained" color="error"
            onClick={handleDeleteRow}>
            {t('dashboard.global.delete')}
          </Button>
        }
      />
    </>
  );
}