import { useEffect, useState } from 'react';
import { Box, Typography, Button, DialogContent, DialogActions, DialogTitle, Dialog, Stepper, Step, StepLabel, TextField, Autocomplete, Chip } from '@mui/material';
import { ComponentBlock } from 'src/sections/mui/component-block';
import Iconify from 'src/components/iconify';
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useAddActiveModeMutation, useGetActiveModeQuery, useGetCampaignsQuery, useGetCountriesQuery, useGetKeywordsQuery } from 'src/context/api/liveBidding/api';
import { useSnackbar } from 'notistack';

interface AddBiddingStrategyModalProps {
    open: boolean;
    onClose: () => void;
}

const steps = ['Step 1: Basic Info', 'Step 2: Targeting', 'Step 3: Review'];

export default function AddBiddingStrategyModal({ open, onClose }: AddBiddingStrategyModalProps) {
    const [activeStep, setActiveStep] = useState(0);
    const [selection, setSelection] = useState('Search');
    const [subSelection, setSubSelection] = useState('Active Mode');
    const workspace: any = useCurrentWorkspace();

    // État pour gérer les erreurs
    const [keywordError, setKeywordError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [campaignError, setCampaignError] = useState(false);
    const [deviceError, setDeviceError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const [addActiveMode, { isLoading, isSuccess, isError }] = useAddActiveModeMutation();

    // API queries
    const { refetch: refetchActiveMode } = useGetActiveModeQuery({
        workspaceId: workspace?.id,
    });

    const [formData, setFormData]: any = useState({
        keywords: [],
        country: '',
        frequency: '10 min',
        device_gads: ['desktop'],
        device: ['desktop'],
        campaign_name: [],
        campaign_id: [],
        home_language: "",
        domain: '',
        mode: "Active Mode",
        country_code: "",
        account_id: "",
        campaign_type: selection,
        client: '',
        workspaceId: null
    });

    const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
        client: workspace?.bqTable
    })

    const { data: campaignOptions, isLoading: isLoadingCampaigns } = useGetCampaignsQuery({
        client: formData.client,
        keyword: formData.keywords
    }, {
        skip: formData.keywords.length === 0
    });

    // Appel à l'API pour récupérer les pays
    const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
        client: formData.client,
    });

    useEffect(() => {
        if (workspace) {
            setFormData(
                {
                    ...formData,
                    domain: workspace?.url,
                    client: workspace?.bqTable,
                    workspaceId: workspace?.id
                });
        }
    }, [workspace])

    const handleNext = () => {
        if (activeStep === 1) {
            let hasError = false;

            if (!formData.keywords) {
                setKeywordError(true);
                hasError = true;
            }

            if (!formData.country) {
                setCountryError(true);
                hasError = true;
            }

            if (formData.campaign_name.length === 0) {
                setCampaignError(true);
                hasError = true;
            }

            if (formData.device.length === 0) {
                setDeviceError(true);
                hasError = true;
            }

            if (hasError) {
                return;
            }
        }

        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
    const isLastStep = activeStep === steps.length - 1;


    const handleFinish = async () => {
        try {
            const result = await addActiveMode(formData).unwrap();
            enqueueSnackbar('Bidding strategy added successfully!', { variant: 'success' });
            refetchActiveMode();
            onClose();
        } catch (error) {
            enqueueSnackbar('Failed to add bidding strategy!', { variant: 'error' });
        }
    };

    const handleKeywordChange = (value: any) => {
        setFormData({
            ...formData,
            keywords: value,
            campaign_name: [],
            campaign_id: [],
        });

        if (value) {
            setKeywordError(false);
        }
    };

    const handleDeviceGAdsChange = (device: string) => {
        if (formData.device_gads.includes(device)) {
            if (formData.device_gads.length > 1) {
                setFormData({
                    ...formData,
                    device_gads: formData.device_gads.filter((d: any) => d !== device)
                });
            }
        } else {
            setFormData({
                ...formData,
                device_gads: [...formData.device_gads, device]
            });
        }
    };

    const handleDeviceChange = (device: any) => {
        setFormData({ ...formData, device: device });
        if (device.length > 0) {
            setDeviceError(false);
        }
    };

    const handleCountryChange = (event: any, newValue: any) => {
        setFormData({
            ...formData,
            country: newValue?.country_name || '',
            country_code: newValue?.country_code || '',
            home_language: newValue?.home_language || ''
        });

        if (newValue) {
            setCountryError(false);
        }
    };

    const handleCampaignChange = (event: any, newValue: any) => {
        // Vérifier si newValue est défini et contient des objets
        if (newValue && newValue.length > 0) {
            // Extraire les noms et les IDs des campagnes sélectionnées
            const campaign_names = newValue.map((campaign: any) => campaign.campaign_name);
            const campaign_ids = newValue.map((campaign: any) => campaign.campaign_id);

            // Extraire le premier profile_id (account_id) trouvé
            const account_id = newValue[0].profile_id;

            // Mettre à jour le formData avec les noms et les IDs des campagnes sélectionnées
            setFormData({
                ...formData,
                campaign_name: campaign_names,
                campaign_id: campaign_ids,
                account_id: account_id // Mettre une chaîne unique pour account_id
            });

            // Si des campagnes sont sélectionnées, enlever l'erreur
            if (campaign_names.length > 0) {
                setCampaignError(false);
            }
        } else {
            // Si aucune campagne n'est sélectionnée, réinitialisez le formData correspondant
            setFormData({ ...formData, campaign_name: [], campaign_id: [], account_id: '' });
        }
    };



    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Add Bidding Strategy</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box sx={{ mt: 4 }}>
                    {activeStep === 0 && (
                        <StepOneContent
                            selection={selection}
                            setSelection={setSelection}
                            subSelection={subSelection}
                            setSubSelection={setSubSelection}
                            setFormData={setFormData}
                        />
                    )}
                    {activeStep === 1 && subSelection === 'Active Mode' && (
                        <StepTwoContent
                            isLoadingCampaigns={isLoadingCampaigns}
                            campaignOptions={campaignOptions}
                            keywordOptions={keywordOptions}
                            isLoadingKeywords={isLoadingKeywords}
                            isLoadingCountries={isLoadingCountries}
                            countriesOptions={countryOptions}
                            formData={formData}
                            handleKeywordChange={handleKeywordChange}
                            handleCountryChange={handleCountryChange}
                            handleDeviceGAdsChange={handleDeviceGAdsChange}
                            handleDeviceChange={handleDeviceChange}
                            handleCampaignChange={handleCampaignChange}
                            keywordError={keywordError}
                            countryError={countryError}
                            campaignError={campaignError}
                            deviceError={deviceError}
                            subSelection={subSelection}
                            selection={selection}
                        />
                    )}
                    {activeStep === 2 && <StepThreeContent formData={formData} />}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose} sx={{ color: 'white' }}>
                    Cancel
                </Button>
                {activeStep !== 0 && (
                    <Button variant="contained" onClick={handleBack}>
                        Back
                    </Button>
                )}
                <Button variant="contained" onClick={isLastStep ? handleFinish : handleNext}>
                    {isLastStep ? 'Finish' : 'Next'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


// Step 1: Basic Info
const StepOneContent = ({ selection, setSelection, subSelection, setSubSelection, setFormData }: any) => {
    const buttonStyle = (isSelected: boolean) => ({
        backgroundColor: isSelected ? 'black' : 'transparent',
        color: 'white',
        border: `1px solid ${isSelected ? 'black' : 'gray'}`,
        width: '150px',
        height: '50px',
        margin: '10px',
    });

    const handleSelection = (value: string) => {
        setSelection(value);
    };

    const handleSubSelection = (value: string) => {
        setSubSelection(value);
    };

    return (
        <Box>
            <ComponentBlock sx={{ marginTop: 7 }} title="Choose your bidding type">
                <Box display="flex" justifyContent="row">
                    <Button
                        style={buttonStyle(selection === 'Search')}
                        onClick={() => handleSelection('Search')}
                    >
                        Search
                    </Button>
                    <Button
                        style={buttonStyle(selection === 'Shopping')}
                        onClick={() => handleSelection('Shopping')}
                    >
                        Shopping
                    </Button>
                    <Button
                        style={buttonStyle(selection === 'pMax')}
                        onClick={() => handleSelection('pMax')}
                    >
                        pMax
                    </Button>
                </Box>
            </ComponentBlock>

            {selection === 'Search' && (
                <ComponentBlock sx={{ marginTop: 4, mb: 1 }} title="Choose your search mode">
                    <Box display="flex" justifyContent="row">
                        <Button
                            style={buttonStyle(subSelection === 'Active Mode')}
                            onClick={() => handleSubSelection('Active Mode')}
                        >
                            Active Mode
                        </Button>
                        <Button
                            style={buttonStyle(subSelection === 'Ko Bidding')}
                            onClick={() => handleSubSelection('Ko Bidding')}
                        >
                            Ko Bidding
                        </Button>
                    </Box>
                </ComponentBlock>
            )}
        </Box>
    );
};

// Step 2: Formulaire Active Mode
const StepTwoContent = ({ formData, campaignOptions, isLoadingCampaigns, isLoadingCountries, countriesOptions, handleKeywordChange, keywordOptions, isLoadingKeywords, keywordError, deviceError, campaignError, countryError, handleCountryChange, handleDeviceChange, handleDeviceGAdsChange, handleCampaignChange }: any) => {
    const countries = [{ country_name: 'USA', country_code: 'us' }, { country_name: 'France', country_code: 'fr' }];

    const isKeywordSelected = formData.keywords.length > 0; // Détermine si un mot-clé est sélectionné

    return (
        <>
            <Box>
                {/* Keywords Field */}
                <ComponentBlock sx={{ marginTop: 5 }} title="Keyword (Exact Match)">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple={false}
                            options={isLoadingKeywords ? [] : keywordOptions || []}
                            value={formData.keywords}
                            onChange={(event, newValue) => handleKeywordChange(newValue)}
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Keyword (Exact Match)"
                                    placeholder="Enter keywords"
                                    error={keywordError}
                                    helperText={keywordError ? "At least one keyword is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: keywordError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ComponentBlock>

                {/* campaign_name Field */}
                <ComponentBlock sx={{ marginTop: 3 }} title="Campaign(s) Selection">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            disabled={!isKeywordSelected}
                            options={isLoadingCampaigns ? [] : campaignOptions || []}  // options = tableau d'objets campagne
                            getOptionLabel={(option: any) => option?.campaign_name || ''}  // Gérer l'affichage du nom des campagnes
                            value={campaignOptions?.filter((option: any) => formData.campaign_name.includes(option.campaign_name)) || []}  // Assurez-vous que la sélection actuelle correspond à ce qui est dans formData
                            onChange={handleCampaignChange}  // Met à jour formData avec les campagnes sélectionnées
                            renderTags={(value: any[], getTagProps) =>
                                value.map((option: any, index: number) => (
                                    <Chip
                                        label={option.campaign_name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Campaign(s)"
                                    placeholder="Select a campaign"
                                    error={campaignError}
                                    helperText={campaignError ? "At least one campaign is required" : ""}
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: campaignError ? 'red' : '',
                                                },
                                            },
                                        },
                                    }}
                                />
                            )}
                        />

                    </Box>
                </ComponentBlock>

                {/* Country Field */}
                <ComponentBlock sx={{ marginTop: 3 }} title="Country">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            options={isLoadingCountries ? [] : countriesOptions || []}
                            getOptionLabel={(option: any) => option.country_name || ''}
                            onChange={handleCountryChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Select a country"
                                    error={countryError}
                                    helperText={countryError ? "Country is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: countryError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ComponentBlock>


                <ComponentBlock sx={{ marginTop: 3 }} title="Active Mode Device(s)">
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                        <Box
                            onClick={() => handleDeviceGAdsChange('desktop')}
                            sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        >
                            <Iconify color={formData.device_gads.includes('desktop') ? "#8339f6" : "#9faab7"} fontSize={16} mr={1} icon={"ph:desktop"} />
                            <Typography fontSize={15} variant="body2">Scrap Desktop Results</Typography>
                        </Box>
                        <Box
                            onClick={() => handleDeviceGAdsChange('mobile')}
                            sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        >
                            <Iconify color={formData.device_gads.includes('mobile') ? "#8339f6" : "#9faab7"} fontSize={16} mr={1} icon={"clarity:mobile-solid"} />
                            <Typography fontSize={15} variant="body2">Scrap Mobile Results</Typography>
                        </Box>
                    </Box>
                </ComponentBlock>

                {/* Scraping Device(s) Field */}
                <ComponentBlock sx={{ marginTop: 3, mb: 2 }} title="Scraping Device(s)">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            options={formData.device_gads}
                            value={formData.device}
                            onChange={(event, newValue) => handleDeviceChange(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Scraping Device(s)"
                                    placeholder="Select devices"
                                    error={deviceError}
                                    helperText={deviceError ? "At least one device is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: deviceError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ComponentBlock>
            </Box>
        </>
    );
};

// Step 3: Review
const StepThreeContent = ({ formData }: any) => {
    return (
        <Box sx={{ mt: 4 }}>
            <ComponentBlock sx={{ marginTop: 2 }} title="Review Your Selections(s)">
                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Bidding Type">
                    <Typography variant="body1">Bidding Type: {formData.campaign_type}</Typography>
                    <Typography variant="body1">Search Mode: {formData.mode}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Keyword (Exact Match)">
                    <Chip label={formData.keywords} sx={{ mr: 1, mb: 1 }} />

                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign(s)">
                    <Typography variant="body1">
                        {formData.campaign_name}
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Country">
                    <Typography variant="body1">
                        {formData.country}
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2, mb: 2 }} title="Active Mode Device(s)">
                    <Typography variant="body1">
                        {formData.device_gads.join(", ")}
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2, mb: 2 }} title="Scraping Device(s)">
                    <Typography variant="body1">
                        {formData.device.join(", ")}
                    </Typography>
                </ComponentBlockCustom>
            </ComponentBlock>
        </Box>
    );
};