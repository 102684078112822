import { Button, Paper, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useCallback, useState } from "react";
import DataGridCustomLiveBiddingBasedActiveMode from "src/components/data-grid/data-grid-custom-live-bidding-based-active-mode";
import DataGridCustomLiveBiddingBasedKoBidding from "src/components/data-grid/data-grid-custom-live-bidding-based-ko-bidding";
import Iconify from "src/components/iconify";
import { useDeleteActiveModeMutation, useGetActiveModeQuery, useGetKoBiddingQuery } from "src/context/api/liveBidding/api";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { enqueueSnackbar } from "notistack";

export default function KeywordBased() {
    const [viewMode, setViewMode] = useState("active");
    const theme = useTheme();

    const [isEditedActiveMode, setIsEditedActiveMode] = useState(false);
    const [isResetActiveMode, setIsResetActiveMode] = useState(false);
    const [editedRowsActiveMode, setEditedRowsActiveMode] = useState<any>([]);

    const [isEditedKoBidding, setIsEditedKoBidding] = useState(false);
    const [isResetKoBidding, setIsResetKoBidding] = useState(false);
    const [editedRowsKoBidding, setEditedRowsKoBidding] = useState<any>([]);

    const workspace = useCurrentWorkspace();
    const [deleteActiveMode] = useDeleteActiveModeMutation();

    const { data: dataActiveMode, error: dataActiveModeError, isFetching: isFetchingDataActiveMode, refetch: refetchActiveMode } = useGetActiveModeQuery({
        workspaceId: workspace?.id,
    });

    const { data: dataKoBidding, error: dataKoBiddingError, isFetching: isFetchingDataKoBidding, refetch: refetchKoBidding } = useGetKoBiddingQuery({
        workspaceId: workspace?.id,
    });

    const dataKeywordBasedActiveMode = dataActiveMode?.map((item: any) => ({
        id: item?.id,
        keyword: item?.keyword,
        country: item?.country,
        campaigns: item?.campaign_name,
        campaign_id: item?.campaign_id,
        frequency: item?.frequency,
        device_gads: item?.device_gads,
        device: item?.device,
        status: item?.status
    }));

    const dataKeywordBasedKoBidding = dataKoBidding?.map((item: any) => ({
        id: item?.id,
        keyword: item?.keyword,
        platform: item?.platform,
        country: item?.country,
        frequency: item?.frequency,
        device: item?.device,
        status: item?.status,
        mode: item?.mode,
        max_cpc: item?.max_cpc,
        account: item?.account_id,
        campaigns: item?.campaign_name,
        ad_group: item?.ad_group_name,
        match_type: item?.match_type,
    }));

    const handleEditedRowsChangeActiveMode = useCallback((newEditedRows: any) => {
        setEditedRowsActiveMode(newEditedRows);
        setIsEditedActiveMode(newEditedRows.length > 0);
    }, []);

    const handleSaveActiveMode = useCallback(async () => {
        try {
            const updates: any = editedRowsActiveMode.map(({ id, changes }: any) => {
                // Extraire les informations du pays si elles sont présentes
                const { ...otherChanges } = changes;

                const updateData = {
                    id,
                    ...otherChanges,
                };

                return updateData;
            });

            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true)
                .map((item: any) => item.id);

            // Supprimer les éléments marqués comme supprimés
            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteActiveMode({ id: id }).unwrap())
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            refetchActiveMode();
            setIsEditedActiveMode(false);
            setEditedRowsActiveMode([]);

        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRowsActiveMode, deleteActiveMode, refetchActiveMode]);

    const handleDiscardActiveMode = () => {
        setIsResetActiveMode(true);
        setEditedRowsActiveMode([]);
        setIsEditedActiveMode(false);
        setTimeout(() => setIsResetActiveMode(false), 0);
    };

    const handleEditedRowsChangeKoBidding = useCallback((newEditedRows: any) => {
        setEditedRowsKoBidding(newEditedRows);
        setIsEditedKoBidding(newEditedRows.length > 0);
    }, []);

    const handleSaveKoBidding = useCallback(async () => {
        try {
            // Logique pour sauvegarder les changements pour Ko Bidding
            refetchKoBidding();
            setIsEditedKoBidding(false);
            setEditedRowsKoBidding([]);
        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRowsKoBidding, refetchKoBidding]);

    const handleDiscardKoBidding = () => {
        setIsResetKoBidding(true);
        setEditedRowsKoBidding([]);
        setIsEditedKoBidding(false);
        setTimeout(() => setIsResetKoBidding(false), 0);
    };

    console.log(editedRowsActiveMode, "editedRowsActiveMode")

    return (
        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
            <Paper
                sx={{
                    backgroundColor: '#212b36',
                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                    height: 'auto',
                    borderRadius: 2,
                    marginBottom: 1
                }}
            >
                <Box style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10, marginBottom: 35 }}>
                    <Box style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25, marginBottom: '5px' }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Search live Bidding Strategies
                        </Typography>
                        <Typography variant="overline" color="textSecondary" sx={{ textTransform: 'none', fontSize: 14 }}>
                            List of keyword-based live bidding strategies for search campaigns
                        </Typography>
                    </Box>

                    <Box style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => console.log()}
                            startIcon={<Iconify icon="mingcute:add-line" />}
                        >
                            Add keyword(s)
                        </Button>
                        {(viewMode === "active" && isEditedActiveMode === true) && (
                            <>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleDiscardActiveMode}
                                    startIcon={<Iconify icon="material-symbols:refresh" />}
                                    sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                >
                                    Discard All Changes
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleSaveActiveMode}
                                    sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                    startIcon={<Iconify icon="tabler:check" />}
                                >
                                    Apply All Changes
                                </Button>
                            </>
                        )}

                        {(viewMode !== "Active Mode" && editedRowsKoBidding === true) && (
                            <>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleDiscardKoBidding}
                                    startIcon={<Iconify icon="material-symbols:refresh" />}
                                    sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                >
                                    Discard All Changes
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleSaveKoBidding}
                                    sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                    startIcon={<Iconify icon="tabler:check" />}
                                >
                                    Apply All Changes
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>

                <Box sx={{ backgroundColor: '#2f3944', width: '100%', py: 2, px: 1, borderBottom: '1px dashed #434e58' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
                        <Button
                            startIcon={<Iconify icon="material-symbols:mode-off-on" />}
                            sx={{ backgroundColor: viewMode === "active" ? '#000000' : '#36414c', color: 'white', px: 6, py: 1.5 }}
                            onClick={() => setViewMode("active")}
                        >
                            Active Mode
                        </Button>
                        <Button
                            startIcon={<Iconify icon="tdesign:arrow-up-down-3" />}
                            sx={{ backgroundColor: viewMode !== "active" ? '#000000' : '#36414c', color: 'white', px: 6, py: 1.5 }}
                            onClick={() => setViewMode("koBidding")}
                        >
                            Ko Bidding
                        </Button>
                    </Box>
                </Box>

                {viewMode === "active" && dataActiveMode ? (
                    <DataGridCustomLiveBiddingBasedActiveMode pageSize={20}
                        onEdit={(it: any) => setIsEditedActiveMode(it)}
                        handleSave={handleEditedRowsChangeActiveMode}
                        data={dataKeywordBasedActiveMode}
                        onApply={isEditedActiveMode}
                        onReset={isResetActiveMode}
                    />
                ) : dataKoBidding && (
                    <DataGridCustomLiveBiddingBasedKoBidding pageSize={20}
                        onEdit={handleEditedRowsChangeKoBidding}
                        handleSave={handleSaveKoBidding}
                        data={dataKeywordBasedKoBidding}
                        onApply={isEditedKoBidding}
                        onReset={isResetKoBidding}
                    />
                )}
            </Paper>
        </Box>
    );
}
