import { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Stack, useTheme } from '@mui/system';
import { Box, LinearProgress, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { setFilterDate } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { formatWeekLabel } from 'src/utils/dates-labels';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = {
  data: {
    id: string;
    keyword: string;
  }[];
};

export default function DataGridCustomDay({ data }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const { t, currentLang } = useLocales();
  const workspace = useCurrentWorkspace();

  const createColumns = (theme: any, t: any, formatCurrency: any, filterStore: any): GridColDef[] => [
    {
      field: 'date',
      headerName: t('dashboard.global.date'),
      flex: 1,
      valueGetter: (params: any) => {
        if (filterStore.timestep === 'date') {
          const dateObj = new Date(params);
          if (dateObj.getDate()) {
            const date = currentLang.value === 'fr' ?  format(new Date(params), "dd/MM/yyyy", { locale: fr }) : format(new Date(params), "MM/dd/yyyy", { locale: fr })
            return date
          }

        } else if (filterStore.timestep === 'year_week') {
          const dateObj = new Date(params);
          if (dateObj.getDate()) {
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            const newDate = currentLang.value === 'fr' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
            return newDate

          } else {
            const [year, week] = params.split('-');
            const newDate = formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
            return newDate
          }

        }
        else {
          const [year, week] = params.split('-');
          return `${week}/${year}`
          // return params
        }

      },
    },
    {
      field: 'brandSearches',
      headerName: t('dashboard.brandTraffic.brandSearches'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18' }}>
          {params.row.brandSearches}
        </span>
      ),
    },
    {
      field: 'totalBrandTraffic',
      headerName: t('dashboard.brandTraffic.totalBrandTraffic'),
      flex: 1,
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F' }}>
          {params.row.totalBrandTraffic}
        </span>
      ),
      type: 'number',
    },
    {
      field: 'organicBrandTraffic',
      headerName: t('dashboard.brandTraffic.organicBrandTraffic'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49' }}>
          {params.row.organicBrandTraffic}
        </span>
      ),
    },
    {
      field: 'paidBrandTraffic',
      headerName: t('dashboard.brandTraffic.paidBrandTraffic'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C' }}>
          {params.row.paidBrandTraffic}
        </span>
      ),
    },
    {
      field: 'uncapturedBrandTraffic',
      headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7' }}>
          {params.row.paidBrandTraffic}
        </span>
      ),
    },
    {
      field: 'blendedCtr',
      headerName: t('dashboard.global.blended_ctr'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.blendedCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#3d1873' : 'rgb(61, 24, 115, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#7635dc',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.blendedCtr === 0 ? '0%' : fPercent(params.row.blendedCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'paidCtr',
      headerName: t('dashboard.brandTraffic.paidCtr'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.paidCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#006C9C',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.paidCtr === 0 ? '0%' : fPercent(params.row.paidCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'organicCtr',
      headerName: t('dashboard.brandTraffic.organicCtr'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.organicCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#19383a' : 'rgb(25, 56, 58, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#065E49',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.organicCtr === 0 ? '0%' : fPercent(params.row.organicCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'uncapturedPercent',
      headerName: t('dashboard.brandTraffic.percentUncaptured'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.uncapturedPercent}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#454d54' : 'rgb(69, 77, 84, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#ADADAD',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.uncapturedPercent === 0 ? '0%' : fPercent(params.row.uncapturedPercent)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'adCost',
      headerName: t('dashboard.global.ad_cost'),
      flex: 1,
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
          {formatCurrency(params.row.adCost, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
        </span>
      ),
      type: 'number',
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleRowClick = (params: any) => {
    const clickedDate = params?.row?.date;

    if (typeof clickedDate === 'string') {
      const parsedDate = parse(clickedDate, 'dd MMMM yyyy', new Date(), { locale: fr });
      const parsedDateISOString = parsedDate.toISOString();

      if (filterStore.start === parsedDateISOString && filterStore.end === parsedDateISOString) {
        const newStartDate = new Date();
        newStartDate.setMonth(newStartDate.getMonth() - 1);
        newStartDate.setDate(newStartDate.getDate() + 1);

        const newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1);

        dispatch(setFilterDate({
          start: newStartDate.toISOString(),
          end: newEndDate.toISOString(),
        }));

      } else {
        dispatch(setFilterDate({
          start: parsedDateISOString,
          end: parsedDateISOString,
        }));
      }
    }
  }

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };


  const formatCurrency = (number: any, options: any, isAdCost = false) => {
    const lang: any = localStorage.getItem('i18nextLng');
    const currencySymbol = workspace?.currency === "usd" ? '$' : '€';

    let maximumFractionDigits = 2; // Valeur par défaut
    if (number >= 1000000) {
      maximumFractionDigits = 1; // Moins de décimales pour des millions
    } else if (number >= 10000) {
      maximumFractionDigits = 1; // Moins de décimales pour des milliers
    } else if (number < 1) {
      maximumFractionDigits = 4; // Plus de décimales pour des valeurs très petites
    }

    options = { ...options, maximumFractionDigits }; // Appliquer les décimales calculées dynamiquement

    let formattedNumber;
    if (isAdCost) {
      if (number >= 1000000) {
        formattedNumber = (number / 1000000).toLocaleString(lang, { maximumFractionDigits }) + 'M';
      } else if (number >= 10000) {
        formattedNumber = (number / 1000).toLocaleString(lang, { maximumFractionDigits }) + 'K';
      } else {
        formattedNumber = number.toLocaleString(lang, options);
      }
    } else if (number >= 1000000) {
      formattedNumber = (number / 1000000).toLocaleString(lang, { maximumFractionDigits }) + 'M';
    } else if (number >= 10000) {
      formattedNumber = (number / 1000).toLocaleString(lang, { maximumFractionDigits }) + 'K';
    } else {
      formattedNumber = number.toLocaleString(lang, options);
    }

    if (lang === 'fr') {
      formattedNumber = formattedNumber.replace('.', ',');
    }

    return currencySymbol + formattedNumber;
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    >
      <DataGridPro
        disableDensitySelector
        autoHeight
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination
        onRowClick={handleRowClick}
        paginationModel={paginationModel}
        columns={createColumns(theme, t, formatCurrency, filterStore)}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}