import { useMemo } from 'react';
// routes
import { ROOTS, paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';

import SvgColor from 'src/components/svg-color';
import { useGetWorkspacesQuery } from 'src/context/api/workspaces';
import { useCurrentUserQuery } from 'src/context/api/auth';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  brandPerformance: icon('solar--chart-2-bold-duotone'),
  brandTraffic: icon('solar--pie-chart-2-bold-duotone'),
  competitiveIntelligence: icon('solar--ranking-bold-duotone'),
  adminUsers: icon('solar--users-group-rounded-bold-duotone'),
  dashboard: icon('ic_dashboard'),
  store: icon('ic_app_store'),
  play: icon('ic_play'),
  liveMonitoring: icon("solar--card-search-bold-duotone"),
  liveBiddingStategies: icon("solar--card-transfer-bold-duotone"),
  workspaces: icon("solar--widget-bold-duotone")
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const workspaces = useGetWorkspacesQuery(null);
  const user: any = useCurrentUserQuery();

  const role = user?.data?.role;


  const data = useMemo(
    () => [
      {
        subheader: 'Dashboard',
        items: [
          {
            title: t('nav.brandPerformance'),
            path: paths.dash.businessOverview,
            icon: ICONS.brandPerformance,
            children: [
              {
                title: "Brand Overview",
                path: paths.dash.brandOverview,
              },
              {
                title: t('nav.businessOverview'),
                path: paths.dash.businessOverview,
              },
            ],
          },
          {
            title: t('nav.brandTraffic'),
            path: paths.dash.trafficAnalysis,
            icon: ICONS.brandTraffic,
            children: [
              {
                title: t('nav.trafficAnalysis'),
                path: paths.dash.trafficAnalysis,
              },
              {
                title: t('nav.trafficDetails'),
                path: paths.dash.trafficDetails,
              },
            ],
          },
          {
            title: t('nav.competitiveIntelligence'),
            path: paths.dash.competitorsMonitoring,
            icon: ICONS.competitiveIntelligence,
            children: [
              {
                title: t('nav.competitorsMonitoring'),
                path: paths.dash.competitorsMonitoring,
              },
              {
                title: t('nav.competitorFocus'),
                path: paths.dash.competitorFocus,
              },
            ],
          },
        ],
      },
      ...(role === "admin" ? [{
        subheader: 'Features',
        items: [
          {
            title: "Live monitoring",
            path: ROOTS.ADMIN_LIVE_MONITORING,
            icon: ICONS.liveMonitoring,
            children: [
              {
                title: t('dashboard.liveMonitoring.keywords'),
                path: paths.admin.liveMonitoring,
              },
              {
                title: t('dashboard.liveMonitoring.add_keyword'),
                path: paths.admin.liveMonitoringAdd,
              },
            ]
          },
          {
            title: "Live Bidding Strategies",
            path: ROOTS.ADMIN_LIVE_BIDDING,
            icon: ICONS.liveBiddingStategies,
            children: [
              {
                title: "Search",
                path: paths.admin.liveBiddingKeywordBased,
                span: "Keyword-based",
              },
              {
                title: "Shopping",
                path: paths.admin.liveBiddingCampaignBased,
                span: "Campaign-based",
              },
              {
                title: "Pmax",
                path: paths.admin.liveBiddingAdGroupBased,
                span: "Ad group-based",
              },
              {
                title: "Add New Bidding Strategy",
                path: "#",
                modal: "addLiveBidding"
              },
            ]
          },
        ]
      }] : []),
      ...(role === "admin" ? [{
        subheader: 'Administration',
        items: [
          {
            title: t('nav.users'),
            path: ROOTS.ADMIN_USERS,
            icon: ICONS.adminUsers,
            children: [
              {
                title: t('nav.list'),
                path: paths.admin.user,
              },
              {
                title: t('nav.add_User'),
                path: paths.admin.userAdd,
              },
            ]
          },
          {
            title: "Workspaces",
            path: "#",
            icon: ICONS.workspaces,
            children: [
              {
                title: t('nav.list'),
                path: paths.admin.workspaces,
              },
              {
                title: t('dashboard.newWorkspace'),
                path: paths.admin.workspacesAdd,
              },
            ]
          },
        ],
      }] : []),
    ],
    [t, workspaces.data, role]
  );

  return data;
}
