import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { Box, Skeleton, Typography } from '@mui/material';
import DataGridCustomPresence from 'src/components/data-grid/data-grid-custom-presence-keyword-domain';
import ChartColumn from "src/sections/charts/chart-column-custom";
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import { formatWeekLabel } from 'src/utils/dates-labels';
import { useState } from 'react';


// ----------------------------------------------------------------------

interface Props {
  post: any;
  info: any;
  isFetching?: boolean;
}

export default function ProfilePostItem({ post, info, isFetching }: Props) {
  const { t, currentLang } = useLocales();
  const filterStore = useSelector((state: any) => state.filters);

  const dataPresence = post?.presence?.map((item: any, index: any) => ({
    id: index,
    search_term: item?.search_term,
    presenceRate: item?.presence_rate * 100
  })) || [];

  const seriesCompetitorsOverTime = [];
  const labelsCompetitorsOverTime = [];

  if (post?.competitorsOverTime) {
    const domainGroups: any = Object.entries(post?.competitorsOverTime);

    for (const [domain, data] of domainGroups) {
      seriesCompetitorsOverTime.push({
        name: domain,
        data: data.map((item: any) => item.ratio_present_scrap * 100),
      });
    }

    if (domainGroups.length > 0) {
      if (filterStore.timestep === 'year_week') {
        labelsCompetitorsOverTime.push(...domainGroups[0][1].map((item: any) => {
          const dateObj = new Date(item.timestep);

          if (dateObj.getDate()) {
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            const newDate = currentLang.value === 'fr' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
            return newDate
          } else {
            const [year, week] = item.timestep.split('-');
            return formatWeekLabel(parseInt(year), parseInt(week), currentLang.value);
          }
        }))

      }else if (filterStore.timestep === "year_month") {
        labelsCompetitorsOverTime.push(...domainGroups[0][1].map((item: any) => {
          const [year, month] = item.timestep.split('-');
          return `${month}/${year}`;
        }));
      } else {
        labelsCompetitorsOverTime.push(...domainGroups[0][1].map((item: any) => {
          const newDate = new Date(item.timestep);
          const month = String(newDate.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based, so we add 1
          const day = String(newDate.getDate()).padStart(2, '0');
          const year = newDate.getFullYear();
          if (currentLang.value === 'fr') {
            return `${day}/${month}/${year}`;
          } else {
            return `${month}/${day}/${year}`;
          }
        }));

      }

    }
  }

  // Helper function to transform the domain URL
  const transformDomain = (domainUrl: any) => {
    if (!domainUrl) return '';
    const domainRemoved = domainUrl.replace(/\.[^/.]+$/, '');
    return domainRemoved.split('-').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };


  const displayName = info?.name || transformDomain(info?.domain);

  return (
    <>
      <Card sx={{ height: "auto" }}>
        {dataPresence?.length === 0 || isFetching ?
          <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
            <>
              <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
              <Typography color={"success.main"}>Active</Typography>
            </>
          </Skeleton>
          : <>
            <CardHeader
              disableTypography
              title={
                <Typography color="inherit" variant="subtitle1">
                  {displayName} {t('dashboard.competitorPage.is_bidding_on_your_brand_keywords')}
                </Typography>
              }
            />

            <Box sx={{ mt: 2 }}>
              <DataGridCustomPresence pageSize={5} data={dataPresence} />
            </Box>
          </>
        }
      </Card>

      <Card sx={{ height: 500 }}>
        {dataPresence?.length === 0 || isFetching ?
          <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
            <>
              <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
              <Typography color={"success.main"}>Active</Typography>
            </>
          </Skeleton>
          : <>
            <CardHeader
              disableTypography
              title={
                <Typography color="inherit" variant="subtitle1">
                  {displayName} {t('dashboard.competitorPage.presence_rate_on_your_brand_keywords_over_time')}
                </Typography>
              }
            />
            <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <ChartColumn
                series={seriesCompetitorsOverTime}
                labels={labelsCompetitorsOverTime}
              />
            </Box>
          </>
        }
      </Card>
    </>
  );
}
