import { useState, useCallback, useEffect, useRef } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// _mock
import { _userAbout, _userFeeds, _userFriends, _userGallery, _userFollowers } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
//
import ProfileHome from './profile/profile-home';
import ProfileCover from './profile/profile-cover';
import { useParams } from 'react-router';
import { useGetAdsPlaQuery, useGetAdsTxtQuery, useGetBrandQuery, useGetCompetitorFilterQuery, useGetPresenceSpecificDomainQuery } from 'src/context/api/competitorsMonitoring/api';
import { useDispatch, useSelector } from 'react-redux';
import { fDate } from 'src/utils/format-time';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import TextAds from './text-ads';
import PlaAds from './pla-ads';
import { setFilterLoadedCountry, setFilterLoadedDevice, setFilterLoadedKeyword, setFilterMinDatePicker } from 'src/context/reducers/filter-settings/filterSlice';

// ----------------------------------------------------------------------

export default function Competitor() {
    const { domain } = useParams() as { domain: string };
    const [currentTab, setCurrentTab] = useState('profile');
    const filterStore = useSelector((state: any) => state.filters);
    const workspace: any = useCurrentWorkspace();
    const { t } = useLocales();
    const dispatch = useDispatch();
    const isFirstLoad = useRef(true);

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.bqTable;

    const { data: ads, error: statsError, isFetching } = useGetAdsPlaQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        domain: domain,
        workspaceTable: workspace?.bqTable
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const { data: txtAds, error: statsErrorTxtAds, isFetching: isFetchingTxtAds } = useGetAdsTxtQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        domain: domain,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceTable: workspace?.bqTable
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const TABS = [
        {
            value: 'profile',
            label: t('dashboard.global.profile'),
            icon: <Iconify icon="solar:user-id-bold" width={24} />,
        },
        ...(txtAds?.length > 0
            ? [{
                value: 'Text Ads',
                label: 'Text Ads',
                icon: <Iconify icon="fa-solid:ad" width={24} />,
            }]
            : []),
        ...(ads?.ads?.length > 0
            ? [{
                value: 'Product Listing Ads',
                label: 'Product Listing Ads',
                icon: <Iconify icon="ion:bag-handle" width={24} />,
            }]
            : [])
    ];

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }, []);

    const { data: presenceData, error: dataPresenceError, isFetching: isFetchingDataPresence } = useGetPresenceSpecificDomainQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        additionalDomain: domain
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const { data: competitorData, error: competitorError } = useGetCompetitorFilterQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: domain
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const dataPresence = presenceData?.presenceByCompetitor?.map((item: any, index: any) => ({
        id: index,
        domain: item?.domain,
        presenceRate: item?.presence_rate * 100,
        device: item?.device,
        country: item?.country,
    })) || [];


    useEffect(() => {
        if (presenceData?.minDatePicker) {
            dispatch(setFilterMinDatePicker(presenceData.minDatePicker[0]?.first_date));
        }
    }, [presenceData, dispatch]);

    const { data: brandData, error, isLoading } = useGetBrandQuery({ domain });
    const [hasIcon, setHasIcon] = useState(false);
    const [Icon, setIcon] = useState([]);
    const [Logo, setLogo] = useState([]);

    useEffect(() => {

        if (!brandData?.logos) return;
        const Icon = brandData?.logos.filter((item: any) => item?.type?.includes('icon'));
        const Logo = brandData?.logos.filter((item: any) => item?.type?.includes('logo'));

        if (Icon.length > 0) {
            setHasIcon(true);
            setIcon(Icon[0]?.formats[0]?.src);
        } else {
            setHasIcon(false);
            setLogo(Logo[0]?.formats[0]?.src);
        }
    }, [brandData]);

    useEffect(() => {
        if (presenceData) {
            const competitorKeywords = presenceData?.presence.map((item: any) => ({
                search_term: item.search_term,
                total_organic_searches: item.total_organic_searches,
            }));
            if (isFirstLoad.current && competitorKeywords) {
                dispatch(setFilterLoadedKeyword(competitorKeywords));
                isFirstLoad.current = false; // Mettre à jour le ref pour éviter un futur dispatch
            }
        }

    }, [presenceData, dispatch, location, isFirstLoad]);

    useEffect(() => {
        if (competitorData) {
            const devices = competitorData.filters?.map((item: any) => item.device);
            const countries = competitorData.filters?.map((item: any) => item.country);
            const uniqueCountries = countries.filter((item: any, index: any) => countries.indexOf(item) === index);
            const uniqueDevices = devices.filter((item: any, index: any) => devices.indexOf(item) === index);

            dispatch(setFilterLoadedCountry(uniqueCountries));
            dispatch(setFilterLoadedDevice(uniqueDevices));
        }
    }, [competitorData]);

    return (
        <Container maxWidth="xl" sx={{ marginTop: 5 }}>

            <>
                <Card
                    sx={{
                        mb: 3,
                        height: 290,
                    }}
                >
                    <ProfileCover
                        role={brandData?.domain}
                        name={brandData?.name}
                        employees={brandData?.company?.employees}
                        foundedYear={brandData?.company?.foundedYear}
                        industries={brandData?.company?.industries}
                        locations={brandData?.company?.locations}
                        kind={brandData?.company?.kind}
                        hasIcon={hasIcon}
                        avatarUrl={hasIcon && Icon ? Icon : !hasIcon && Logo ? Logo : brandData?.logos[0]?.formats[0]?.src}
                        coverUrl={brandData?.images[0]?.formats[0]?.src}
                        primaryColor={brandData?.colors[0]?.hex}
                    />

                    <Tabs
                        value={currentTab}
                        onChange={handleChangeTab}
                        sx={{
                            width: 1,
                            bottom: 0,
                            zIndex: 9,
                            position: 'absolute',
                            bgcolor: 'background.paper',
                            [`& .${tabsClasses.flexContainer}`]: {
                                pr: { md: 3 },
                                justifyContent: {
                                    sm: 'center',
                                    md: 'flex-end',
                                },
                            },
                        }}
                    >
                        {TABS.map((tab) => (
                            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                        ))}
                    </Tabs>
                </Card>


                {(currentTab === 'profile') &&
                    <ProfileHome
                        isFetching={isFetchingDataPresence}
                        info={brandData}
                        posts={presenceData}
                        presenceRate={presenceData?.presenceRate[0]?.total_presence_rate}
                        employees={brandData?.company?.employees}
                        foundedYear={brandData?.company?.foundedYear}
                        industries={brandData?.company?.industries}
                        locations={brandData?.company?.locations}
                        kind={brandData?.company?.kind}
                    />
                }

                {(currentTab === 'Text Ads') &&
                    <TextAds domain={domain} workspaceTable={workspace?.bqTable} />
                }


                {(currentTab === 'Product Listing Ads') &&
                    <PlaAds domain={domain} workspaceTable={workspace?.bqTable} />
                }

            </>

        </Container>
    );
}
