import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridToolbar,
  useGridApiContext,
  GridRenderCellParams,
  GridRenderEditCellParams
} from '@mui/x-data-grid-pro';
import { Autocomplete, Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Iconify from '../iconify';
import { useScrapingExistMutation } from 'src/context/api/admin/api';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { usePopover } from '../custom-popover';
import React from 'react';
import { useGetCampaignsQuery, useGetKeywordsQuery, useGetCountriesQuery } from 'src/context/api/liveBidding/api';

// Typing for country and other data structures
type Country = {
  country_code: string;
  country_name: string;
};

type DataRow = {
  id: number;
  keyword: string;
  country: Country;
  frequency: string;
  device: string[];
  status?: boolean;
  isDeleted?: boolean;
  isDuplicate?: boolean; // Added to indicate duplicates
};

// Typing for custom cell components
type EditCellProps = {
  id: number | string;
  value: any;
  field: string;
};

// Custom cell components
const DeviceEditInputCell: any = ({ id, value, field }: EditCellProps) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string[]) => {
      if (newValue.length === 0) {
        return;
      }
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{ width: '100%', '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { padding: 1.3 } }}>
      <Autocomplete
        multiple
        options={['desktop', 'mobile']}
        disableClearable
        value={value || []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disableCloseOnSelect
        size="small"
      />
    </Box>
  );
};

const KeywordEditInputCell = ({ id, value, field, api }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de mots-clés à partir de l'API
  const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
    client: workspace?.bqTable,
  });

  // Gestion du changement de mot-clé
  const handleKeywordChange = (event: any, newValue: string) => {
    // Mettre à jour la valeur du keyword
    api.setEditCellValue({ id, field: 'keyword', value: newValue });
    // Réinitialiser le champ campaigns
    api.setEditCellValue({ id, field: 'campaigns', value: [] });
    // Stopper l'édition du champ keyword
    api.stopCellEditMode({ id, field });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        disableClearable
        options={keywordOptions || []}  // Charger les options de mots-clés
        value={value || ''}  // La valeur actuelle du champ
        onChange={(event, newValue) => handleKeywordChange(event, newValue)}  // Appeler la fonction handleKeywordChange lors d'une modification
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingKeywords}  // Désactiver pendant le chargement
        size='medium'
      />
    </Box>
  );
};

const CountryEditInputCell = ({ id, value, field, api }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  // Appel à l'API pour récupérer les pays
  const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
    client: workspace?.bqTable,
  });

  // Transforme l'objet renvoyé en tableau de pays
  const countriesArray: any = countryOptions ? Object.values(countryOptions) : [];

  const handleCountryChange = (event: any, newValue: Country | null) => {
    // Mettre à jour la valeur du champ `country`
    if (newValue) {
      api.setEditCellValue({ id, field: 'country', value: newValue.country_name });
    }
    // Stopper l'édition après le changement
    api.stopCellEditMode({ id, field });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        disableClearable
        options={countriesArray || []} // Charger les options de pays
        getOptionLabel={(option) => option.country_name || ''} // Utiliser `country_name` comme étiquette
        value={countriesArray.find((opt: any) => opt.country_name === value) || null} // Trouver la valeur actuelle
        onChange={handleCountryChange} // Gérer le changement
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingCountries} // Désactiver si les pays sont en cours de chargement
        size="small"
        isOptionEqualToValue={(option, value) => option.country_name === value} // Comparer les valeurs par `country_name`
      />
    </Box>
  );
};


const CampaignEditInputCell = ({ id, value, field, api, row }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de campagnes en fonction du keyword
  const keyword = row.keyword;

  const { data: campaignOptions, isLoading: isLoadingCampaigns } = useGetCampaignsQuery(
    { client: workspace?.bqTable, keyword },
  );

  const handleCampaignChange = (event: any, newValue: any) => {
    const selectedCampaignNames = newValue.map((campaign: any) => campaign.campaign_name);

    // Mettre à jour la valeur des `campaigns` et `campaign_id`
    api.setEditCellValue({ id, field: 'campaigns', value: selectedCampaignNames });

    // Stopper l'édition une fois la sélection modifiée
    api.stopCellEditMode({ id, field });
  };

  const selectedCampaigns = campaignOptions?.filter((opt: any) => value?.includes(opt.campaign_name)) || [];

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        disableClearable
        options={campaignOptions || []}
        getOptionLabel={(option) => option.campaign_name || ''}
        value={selectedCampaigns}
        onChange={handleCampaignChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        size="small"
        isOptionEqualToValue={(option, value) => option.campaign_id === value?.campaign_id}
      />
    </Box>
  );
};




// Create columns
const createColumns = (
  deleteItem: (id: number | string) => void,
  resetItem: (id: number | string) => void,
  editedRows: Map<number | string, Partial<GridRowModel>>
): GridColDef[] => [
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu params={params} editedRow={editedRows.has(params.id)} deleteItem={deleteItem} resetItem={resetItem} />
      ),
    },
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <KeywordEditInputCell {...params} />
      ),
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: true,
      flex: 1,
      filterable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CountryEditInputCell {...params} />
      ),
    },
    {
      field: 'device_gads',
      headerName: 'Active Mode Device(s)',
      flex: 1,
      editable: true,
      type: 'custom',
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },
    {
      field: 'campaigns',
      headerName: 'Campaign(s)',
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CampaignEditInputCell {...params} />
      ),
    },
    {
      field: 'campaign_id',
      headerName: 'campaign_id',
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true, 
      hideable: true, 
      hideSortIcons: true, 
    },
    {
      field: 'device',
      headerName: 'Scraping Device(s)',
      flex: 1,
      editable: true,
      type: 'custom',
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },

    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
  ];

// Action menu component
type ActionMenuProps = {
  params: GridRenderCellParams;
  editedRow: boolean;
  deleteItem: (id: number | string) => void;
  resetItem: (id: number | string) => void;
};

const ActionMenu = ({ params, deleteItem, resetItem, editedRow }: ActionMenuProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(params.row.id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    resetItem(params.row.id);
  };

  return (
    <div>
      <IconButton
        onClick={handleReset}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        disabled={!editedRow}
      >
        <Iconify sx={{ opacity: !editedRow ? 0.2 : 1 }} icon="material-symbols:refresh" />
      </IconButton>

      <IconButton
        onClick={handleDelete}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    </div>
  );
};

type Props = {
  data: DataRow[];
  pageSize: number;
  deleteItem?: (ids: (number | string)[]) => void;
  handleSave: (editedRows: { id: string | number; changes: Partial<GridRowModel> }[]) => void;
  onEdit: (hasEditedRows: boolean) => void;
  onApply: boolean;
  onReset: boolean;
};

export default function DataGridCustomLiveBiddingBasedKoBidding({
  data,
  pageSize,
  deleteItem,
  handleSave,
  onEdit,
  onApply,
  onReset,
}: Props) {


  const [rows, setRows] = useState<DataRow[]>(data);
  const [initialRows] = useState<Map<number | string, DataRow>>(new Map(data.map(row => [row.id, { ...row }])));
  const [selectionModel, setSelectionModel] = useState<(number | string)[]>([]);
  const [editedRows, setEditedRows] = useState<Map<number | string, Partial<GridRowModel>>>(new Map());
  const [deletedRowIds, setDeletedRowIds] = useState<(number | string)[]>([]);
  const popover = usePopover();
  const [pendingChanges, setPendingChanges] = useState<Map<number | string, Partial<DataRow>>>(new Map());

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
    campaign_id: false,  // Masquer la colonne `campaign_id` par défaut
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = useCallback((newModel: any) => {
    setPaginationModel(newModel);
  }, []);

  const [scrapingExist] = useScrapingExistMutation();
  const workspace: any = useCurrentWorkspace();

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const initialRow: any = initialRows.get(newRow.id);
      const isModified = Object.keys(newRow).some((key) => newRow[key] !== initialRow?.[key]);

      // Vérifiez si le `keyword` ou le `country` ont changé
      const keywordChanged = newRow.keyword !== oldRow.keyword;
      const countryChanged = newRow.country !== oldRow.country;

      if (keywordChanged) {
        // Si le mot-clé change, réinitialisez les `campaigns`
        newRow.campaigns = [];
      }

      let isDuplicate = false;

      if (keywordChanged || countryChanged) {
        // Vérifiez les duplications via l'API ou l'état actuel
        const potentialDuplicates = rows.filter(
          (row) => row.id !== newRow.id && row.keyword === newRow.keyword && row.country === newRow.country
        );

        if (potentialDuplicates.length > 0) {
          isDuplicate = true;
        } else {
          try {
            const response: any = await scrapingExist({
              keywords: [newRow.keyword],
              country: newRow.country,
              workspaceId: workspace?.id,
            }).unwrap();
            isDuplicate = response.isDuplicate;
          } catch (error) {
            isDuplicate = true;
          }
        }
      }

      // Mettez à jour la ligne avec les changements et l'état de duplication
      const updatedRow = {
        ...newRow,
        isDuplicate,
      };

      // Mettez à jour la liste des lignes et enregistrez les modifications
      setRows((prevRows: any) =>
        prevRows.map((row: any) => (row.id === newRow.id ? updatedRow : row))
      );

      if (isModified) {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(newRow.id, updatedRow);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      } else {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(newRow.id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }

      return updatedRow;
    },
    [initialRows, rows, scrapingExist, workspace, handleSave, onEdit]
  );

  useEffect(() => {
    if (data) {
      setRows((prevRows) => {
        // Fusionner les nouvelles données avec l'état local
        const updatedRows = data.map((newRow: DataRow) => {
          const existingRow = prevRows.find((row) => row.id === newRow.id);

          if (existingRow) {
            // Conserver l'état local des lignes modifiées ou supprimées
            if (existingRow.isDeleted) {
              return { ...newRow, isDeleted: true };
            }
            if (editedRows.has(newRow.id)) {
              return existingRow; // Conserver les modifications locales
            }
          }

          // Sinon, retourner la nouvelle ligne telle qu'elle est
          return newRow;
        });

        return updatedRows;
      });
    }
  }, [data, editedRows]); // Ajouter `editedRows` pour éviter de réinitialiser les lignes modifiées




  const handleDeleteItem = useCallback(
    (id: number | string) => {
      // Trouver la ligne à supprimer par ID
      const rowToDelete = rows.find((row) => row.id === id);

      if (rowToDelete) {
        // Mettre à jour uniquement la ligne spécifique à supprimer
        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, isDeleted: true } : row
        );

        // Mettre à jour l'état des lignes
        setRows(updatedRows);
        setDeletedRowIds((prev) => [...prev, id]);

        // Mettre à jour les lignes éditées
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(id, { ...rowToDelete, isDeleted: true });
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [rows, handleSave, onEdit]
  );

  const handleResetItem = useCallback(
    (id: number | string) => {
      const originalRow = data.find((row) => row.id === id);
      if (originalRow) {
        setRows((prevRows) => prevRows.map((row) => (row.id === id ? originalRow : row)));
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [data, handleSave, onEdit]
  );

  const handleReset = useCallback(() => {
    setRows([...data]);
    setEditedRows(new Map());
    setSelectionModel([]);
    setDeletedRowIds([]);
    onEdit(false);
  }, [data, onEdit]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset, handleReset]);

  useEffect(() => {
    if (!onApply) {
      setEditedRows(new Map());
    }
  }, [onApply]);

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  // Get class names for rows and cells
  const getRowClassName = (params: any) => {
    const classes: string[] = [];
    if (params.row.isDeleted) {
      classes.push('deleted');
    } else if (params.row.status === false) {
      classes.push('status-false');
    }
    if (params.row.isDuplicate) {
      classes.push('duplicate'); // Add class for duplicates
    }
    if (editedRows.has(params.id)) {
      classes.push('edited');
    }
    return classes.join(' ');
  };

  const getCellClassName = (params: any) => {
    if (params.row.status === false && ['keyword', 'country', 'frequency', 'device'].includes(params.field)) {
      return 'cell-opacity';
    }
    return '';
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row.edited': {
          backgroundColor: '#666546',
          '&:hover': {
            backgroundColor: '#666546 !important',
          },
        },
        '& .MuiDataGrid-row.deleted': {
          backgroundColor: '#4c3333',
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-row.edited.status-false': {
          backgroundColor: '#666546', /* Override with edited color */
        },
        '& .MuiDataGrid-row.duplicate': {
          backgroundColor: '#4c3333!important', /* Background color for duplicate rows */
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-cell.cell-opacity': {
          opacity: 0.3,
        },
      }}
    >
      <DataGridPro
        disableRowSelectionOnClick
        rows={rows}
        columnVisibilityModel={columnVisibilityModel}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(handleDeleteItem, handleResetItem, editedRows)} // Assurez-vous que les arguments sont corrects        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        disableDensitySelector
        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel as (number | string)[])}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: GridToolbar
        }}
      />
    </Box>
  );
}
