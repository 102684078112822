import { Card, Grid } from "@mui/material";
import Avatar from "./profile-avatar";
import AccountGeneralSettings from "./profile-settings";

export default function AccountGeneral() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ padding: 3, }}>
                    <AccountGeneralSettings/>
                </Card>
            </Grid>
        </Grid>
    )
}    