import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
//
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerFileDetails from './file-manager-file-details';
import FileManagerNewFolderDialog from './file-manager-new-folder-dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import { useAcceptInvitationMutation, useDeclineInvitationMutation, useGetWorkspacesQuery, useInvitationsQuery } from 'src/context/api/workspaces';
import { useRouter } from 'src/routes/hooks';
import { useTheme } from '@emotion/react';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

interface Props extends CardProps {
    folder: any;
    selected?: boolean;
    onSelect?: VoidFunction;
    onDelete: VoidFunction;
}

export default function FileManagerFolderItem({
    folder,
    selected,
    onSelect,
    onDelete,
    sx,
    ...other
}: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const router = useRouter();

    const { copy } = useCopyToClipboard();

    const [inviteEmail, setInviteEmail] = useState('');

    const [folderName, setFolderName] = useState(folder.name);

    const editFolder = useBoolean();

    const checkbox = useBoolean();

    const share = useBoolean();

    const popover = usePopover();

    const confirm = useBoolean();

    const details = useBoolean();

    const favorite = useBoolean(folder.isFavorited);

    const workspaces = useGetWorkspacesQuery(null);

    const handleChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInviteEmail(event.target.value);
    }, []);

    const handleChangeFolderName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFolderName(event.target.value);
    }, []);

    const handleCopy = useCallback(() => {
        enqueueSnackbar('Copied!');
        copy(folder.url);
    }, [copy, enqueueSnackbar, folder.url]);

    const [accept, acceptRequest] = useAcceptInvitationMutation();
    const [decline, declineRequest] = useDeclineInvitationMutation();
    const theme: any = useTheme()
    const invitations: any = useInvitationsQuery(null);
    const { t } = useLocales();

    const renderIcon =
        (checkbox.value || selected) && onSelect ? (
            <Checkbox
                size="medium"
                checked={selected}
                onClick={onSelect}
                icon={<Iconify icon="eva:radio-button-off-fill" />}
                checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
                sx={{ p: 0.75 }}
            />
        ) : (
            <Box sx={{
                p: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#28323D' : ' #F4F6F8',
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                borderRadius: '10px',
                padding: '10px',
                width: 44
            }}>
                <Box component="img" src={`https://www.google.com/s2/favicons?domain=${folder?.workspace?.url}&sz=64`} sx={{ width: 24, height: 24, borderRadius: '5px' }} />
            </Box>
        );

    const renderText = (
        <ListItemText
            onClick={details.onTrue}
            primary={folder.name}

            primaryTypographyProps={{
                noWrap: true,
                typography: 'subtitle1',
            }}
            secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                alignItems: 'center',
                typography: 'caption',
                color: 'text.disabled',
                display: 'inline-flex',
            }}
        />
    );

    const renderAvatar = (
        <AvatarGroup
            max={3}
            sx={{
                [`& .${avatarGroupClasses.avatar}`]: {
                    width: 24,
                    height: 24,
                    '&:first-of-type': {
                        fontSize: 12,
                    },
                },
            }}
        >
            {folder.shared?.map((person: any) => (
                <Avatar key={person.id} alt={person.name} src={person.avatarUrl} />
            ))}
        </AvatarGroup>
    );

    return (
        <Box sx={{ height: 'auto' }}>
            <Stack
                onMouseEnter={checkbox.onTrue} onMouseLeave={checkbox.onFalse}
                component={Paper}
                variant="outlined"
                spacing={1}
                // alignItems="flex-start"
                sx={{
                    padding: 2,
                    borderRadius: 2,
                    bgcolor: 'unset',
                    cursor: 'pointer',
                    position: 'relative',
                    ...((checkbox.value || selected) && {
                        bgcolor: 'background.paper',
                        boxShadow: (theme) => theme.customShadows.z20,
                    }),
                    ...sx,
                }}
                {...other}
            >
                <Box onMouseEnter={checkbox.onTrue} onMouseLeave={checkbox.onFalse}>
                    {renderIcon}
                </Box>

                <Typography>{folder?.workspace?.name}</Typography>

                <span style={{ fontSize: 13 }}>{t('dashboard.invitationToWorkspace')}</span>

                <Box sx={{ display: 'flex', justifyContent: "space-between", gap: 2, flexDirection: { xs: 'column', sm: 'row' }, marginTop: 2 }}>
                    <LoadingButton
                        color="inherit"
                        size="small"
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ width: '100%' }}
                        onClick={() => {
                            accept({ id: folder?.workspace?.id, token: folder?.token }).unwrap().then(() => {

                                enqueueSnackbar('Invitation accepted successfully', { variant: 'success' });
                                workspaces.refetch();
                                invitations.refetch();

                            });
                        }}
                    >
                        {t('dashboard.accept')}
                    </LoadingButton>

                    <LoadingButton
                        color="inherit"
                        size="small"
                        type="submit"
                        variant="outlined"
                        fullWidth
                        sx={{ backgroundColor: '#1C252E', color: 'white', width: '100%' }}
                        onClick={() => {
                            decline({ id: folder?.workspace?.id, token: folder?.token }).unwrap().then(() => {
                                // router.reload()
                                enqueueSnackbar('Invitation declined successfully', { variant: 'success' });
                                workspaces.refetch();
                                invitations.refetch();

                            });
                        }}
                    >
                        {t('dashboard.decline')}
                    </LoadingButton>
                </Box>



                {renderText}

                {!!folder?.shared?.length && renderAvatar}
            </Stack>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        popover.onClose();
                        handleCopy();
                    }}
                >
                    <Iconify icon="eva:link-2-fill" />
                    Copy Link
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        popover.onClose();
                        share.onTrue();
                    }}
                >
                    <Iconify icon="solar:share-bold" />
                    Share
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        popover.onClose();
                        editFolder.onTrue();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete
                </MenuItem>
            </CustomPopover>

            <FileManagerFileDetails
                item={folder}
                favorited={favorite.value}
                onFavorite={favorite.onToggle}
                onCopyLink={handleCopy}
                open={details.value}
                onClose={details.onFalse}
                onDelete={() => {
                    details.onFalse();
                    onDelete();
                }}
            />

            <FileManagerShareDialog
                open={share.value}
                shared={folder.shared}
                inviteEmail={inviteEmail}
                onChangeInvite={handleChangeInvite}
                onCopyLink={handleCopy}
                onClose={() => {
                    share.onFalse();
                    setInviteEmail('');
                }}
            />

            <FileManagerNewFolderDialog
                open={editFolder.value}
                onClose={editFolder.onFalse}
                title="Edit Folder"
                onUpdate={() => {
                    editFolder.onFalse();
                    setFolderName(folderName);
                }}
                folderName={folderName}
                onChangeFolderName={handleChangeFolderName}
            />

            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Delete"
                content="Are you sure want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={onDelete}>
                        Delete
                    </Button>
                }
            />
        </Box>
    );
}
