import { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { useRouter } from 'src/routes/hooks';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import { useTheme } from '@mui/material/styles';

const createColumns = (router: any, t: any): GridColDef[] => [
  {
    field: 'Image',
    headerName: "",
    width: 70,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <Box component="img" src={`https://www.google.com/s2/favicons?domain=${params?.row?.domain}&sz=64`} sx={{ width: 25, height: 25 }} />
      </Stack>
    ),
  },
  {
    field: 'domain',
    headerName: t('dashboard.Competitor'),
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: t('dashboard.global.presenceRate'),
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'success') ||
            (params.row.presenceRate < 30 && 'success') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'error'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
];

type Props = {
  data: {
    id: string;
    role: string;
    email: string;
    profile: {
      firstname: string;
      lastname: string;
    }
  }[];
  pageSize: number;
};

export default function DataGridCustomPresence({ data, pageSize }: Props) {
  const router = useRouter();
  const workspace = useCurrentWorkspace();
  const workspaceId = workspace?.id || 1;
  const { t } = useLocales();
  const theme = useTheme();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  const handleRowClick = (params: any) => {
    router.push(`/dash/competitor/${params?.row?.domain}/?workspace=${workspaceId}`);
  };

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-root': {
          '--unstable_DataGrid-radius': '0px',
        },
        '& .css-jr9x4l-MuiTablePagination-displayedRows': {
          'display': 'none!important'
        },
        '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
          background: theme.palette.mode === "dark" ? '#28323c!important' : '#f4f6f8!important', // Appliquer la couleur de fond
          py: 1.75
        },
        '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
          color: theme.palette.mode === "dark" ? '#959fa9!important' : '#637381!important'
        },
        "& .css-lnsnn8-MuiDataGrid-rowCount": {
          display: 'none!important'
        },
      }}
    >
      <DataGridPro
        autoHeight
        disableColumnMenu
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        columns={createColumns(router, t)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onRowClick={handleRowClick}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    </Box>
  );
}
