import { useState, useCallback, useEffect } from 'react';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
// types
import { IFileShared } from 'src/types/file';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { alpha, useTheme } from '@mui/material/styles';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useDeleteInvitationMutation, useWorkspaceUserDeleteMutation } from 'src/context/api/workspaces';
import { enqueueSnackbar } from 'notistack';
import { useLazyGetUserByEmailQuery } from 'src/context/api/admin/api';

// ----------------------------------------------------------------------

type Props = {
  person: IFileShared;
  workspaceId: number;
  editable?: boolean;

  onInvitation?: (item: any) => void;
};

export default function FileManagerInvitedItem({ person, workspaceId, editable, onInvitation }: Props) {
  const [permission, setPermission] = useState(person.permission);
  const [triggerFetch, { data: userData, error, isLoading }] = useLazyGetUserByEmailQuery();
  const popover = usePopover();
  const { t } = useLocales();
  const theme = useTheme();
  const confirmDelete = useBoolean();
  const [deleteInvitation,] = useDeleteInvitationMutation();
  const [workspaceDeleteUser] = useWorkspaceUserDeleteMutation();
  const [userFind, setUserFind] = useState<{ id: number } | null>(null);


  const fetchUserByEmail = useCallback(async () => {
    if (person?.email) {
      const email = person?.email;
      await triggerFetch({ email })
        .unwrap()
        .then(response => {
          setUserFind(response);
        })
        .catch(err => {
          enqueueSnackbar('Failed to fetch user', { variant: 'error' });
        });
    }
  }, [person.email, triggerFetch, enqueueSnackbar]);

  useEffect(() => {
    if (person?.email) {
      fetchUserByEmail()
    }

  }, [person])

  const handleDeleteRow = async () => {
    try {
      if (userFind) {
        const idUser = userFind?.id;
        if (person.status === 'PENDING') {
          await deleteInvitation({ workspaceId: workspaceId, invitationId: Number(person.id) }).unwrap().then(() => {
            enqueueSnackbar(t('dashboard.snackbar.delete_success'));
            confirmDelete.onFalse();
          })
          onInvitation?.({ "workspaceId": workspaceId, "email": person?.email, "id": Number(person.id), "toDelete": true })

        } else {
          await workspaceDeleteUser({ id: workspaceId, userId: idUser }).unwrap().then(() => {
            enqueueSnackbar(t('dashboard.snackbar.delete_success'));
            confirmDelete.onFalse();
          })
          onInvitation?.({ "workspaceId": workspaceId, "email": person?.email, "id": Number(idUser), "toDelete": true })

        }
        // await deleteInvitation({workspaceId:workspaceId, invitationId:  Number(person.id)}).unwrap().then(() => {
        //   enqueueSnackbar(t('dashboard.snackbar.delete_success'));
        //   confirmDelete.onFalse();
        // })
        //   await workspaceDeleteUser({id:workspaceId,userId:idUser}).unwrap().then(() => { 
        //     enqueueSnackbar(t('dashboard.snackbar.delete_success'));
        //     confirmDelete.onFalse();
        // })
        confirmDelete.onFalse();
      }

    } catch (error) {
      enqueueSnackbar(t('dashboard.snackbar.failed_to_delete_invitation'), { variant: 'error' });
    }

  };

  const handleChangePermission = useCallback((newPermission: string) => {
    setPermission(newPermission);
  }, []);

  return (
    <>
      <ListItem
        sx={{
          px: 0,
          py: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        title={
          person.firstname ?
            person?.firstname + " " + person?.lastname + " - " + person?.email + " - " + person?.role
            : person?.email
        }
      >
        {
          person?.firstname ? (
            <Avatar alt={person.firstname + " " + person.lastname}
              sx={{
                mr: 2,
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',

                background: (theme) =>
                  `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              }} >
              <Typography sx={{ fontSize: '1rem', color: theme.palette.mode === 'dark' ? '#fff' : '#ffffff' }}>
                {person?.firstname?.charAt(0).toUpperCase()}
                {person?.lastname?.charAt(0).toUpperCase()}
              </Typography>
            </Avatar>
          ) : (
            <Avatar
              alt={person.name}
              src={person.avatarUrl}
              sx={{
                mr: 2,
                color: theme.palette.mode === 'dark' ? '#fff' : '#ffffff',
                background: (theme) =>
                  `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              }}
            />

          )
        }

        <ListItemText
          primary={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                {person.firstname ? `${person.firstname} ${person.lastname}` : t('dashboard.global.new_user')}
              </Typography>
              <Typography variant="caption"
                sx={{
                  fontWeight: 'bold',
                  borderRadius: 1,
                  p: 0.5,
                  backgroundColor: theme.palette.mode === 'dark' ? person?.status === 'PENDING' ? '#453E2B' : '#21433A' : person?.status === 'PENDING' ? '#FCEED3' : '#D8F3E2',
                  color: theme.palette.mode === 'dark' ? person?.status === 'PENDING' ? '#FFD666' : '#77ED8B' : person?.status === 'PENDING' ? '#B1741A' : '#118D57',
                }} >
                {person?.status ? person.status.charAt(0) + person.status.slice(1).toLowerCase() : t('dashboard.global.active')}
              </Typography>
            </Stack>

          }
          secondary={
            <Tooltip title={null} >
              <span>{person.email}</span>
            </Tooltip>
          }
          primaryTypographyProps={{ noWrap: true, typography: 'subtitle2' }}
          secondaryTypographyProps={{ noWrap: true, component: 'span' }}
          sx={{ flexGrow: 2, minWidth: 0, mr: 2 }}
        />


        <Box sx={{ mr: 1 }}>
          <IconButton color='error' onClick={confirmDelete.onTrue}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </Box>

        {/* <Typography variant="subtitle2"
          sx={{
            flexGrow: 1,
            textAlign: 'center',

            mr: 2,
            borderRadius: 1,
            p: 1,
            backgroundColor: person?.status === 'PENDING' ? '#44453C' : '#20443B',
            color: person?.status === 'PENDING' ? '#B98616' : '#238A61',
          }} >
          {person?.status ? person.status.charAt(0) + person.status.slice(1).toLowerCase() : t('dashboard.global.active')}
        </Typography> */}
        {
          editable && <Button
            size="small"
            color="inherit"
            endIcon={
              <Iconify
                width={20}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: -1 }}
              />
            }
            onClick={popover.onOpen}
            sx={{
              flexShrink: 0,
              ...(popover.open && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            Can {permission}
          </Button>
        }

      </ListItem>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        <>
          <MenuItem
            selected={permission === 'view'}
            onClick={() => {
              popover.onClose();
              handleChangePermission('view');
            }}
          >
            <Iconify icon="solar:eye-bold" />
            Can view
          </MenuItem>

          <MenuItem
            selected={permission === 'edit'}
            onClick={() => {
              popover.onClose();
              handleChangePermission('edit');
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Can edit
          </MenuItem>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem
            onClick={() => {
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Remove
          </MenuItem>
        </>
      </CustomPopover>

      <ConfirmDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        title="Delete"
        content="Are you sure want to delete this invitation?"
        action={
          <Button variant="contained" color="error" onClick={handleDeleteRow} >
            {t('dashboard.global.delete')}
          </Button>
        }
      />
    </>
  );
}