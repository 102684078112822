import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridToolbar,
  useGridApiContext,
  GridRenderCellParams,
  GridRenderEditCellParams
} from '@mui/x-data-grid-pro';
import { Autocomplete, Box, Chip, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import Iconify from '../iconify';
import { useGetCountriesQuery } from 'src/context/api/countries/api';
import { useScrapingExistMutation } from 'src/context/api/admin/api';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import CustomPopover, { usePopover } from '../custom-popover';
import React from 'react';
import { useGetKeywordsQuery } from 'src/context/api/trafficDetails/api';

// Typing for country and other data structures
type Country = {
  country_code: string;
  country_name: string;
};

type DataRow = {
  id: number;
  keyword: string;
  country: Country;
  frequency: string;
  device: string[];
  status?: boolean;
  isDeleted?: boolean;
  isDuplicate?: boolean; // Added to indicate duplicates
};

// Typing for custom cell components
type EditCellProps = {
  id: number | string;
  value: any;
  field: string;
};

type CountryEditCellProps = EditCellProps & {
  countries: Country[];
};

// Custom cell components
const DeviceEditInputCell: any = ({ id, value, field }: EditCellProps) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string[]) => {
      if (newValue.length === 0) {
        return;
      }
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{ width: '100%', '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { padding: 1.3 } }}>
      <Autocomplete
        multiple
        options={['desktop', 'mobile']}
        disableClearable
        value={value || []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disableCloseOnSelect
        size="small"
      />
    </Box>
  );
};

// Fonction pour obtenir le code du pays à partir du nom du pays
const findCountryByName = (name: any, countries: Country[]): string | null => {
  const country = countries.find(country => country.country_name === name);
  if (country) {
    return country.country_code;
  }
  return null;
}

// Fonction pour obtenir un objet `Country` à partir du nom du pays
const findCountryByNameTest = (name: string, countries: Country[]): Country | null => {
  return countries.find(country => country.country_name === name) || null;
}

const getUniqueAndSortedCountries = (countries: any[]) => {
  // Utiliser un Map pour éliminer les doublons
  const countryMap = new Map(countries.map((country: any) => [country.country_name, country]));

  // Convertir les valeurs du Map en tableau et trier par nom de pays
  return Array.from(countryMap.values())
    .sort((a: any, b: any) => a.country_name.localeCompare(b.country_name))
    .map((country: any) => country.country_name); // Retourner seulement les noms de pays
};


const CountryEditInputCell: any = ({ id, value, field, countries }: CountryEditCellProps) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: Country | null) => {
      if (newValue) {
        apiRef.current.setEditCellValue({ id, field, value: newValue?.country_name }, event);
      }
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, id, field]
  );

  // Convertir la valeur initiale en objet `Country` si elle est une chaîne
  const initialValue = useMemo(() => {
    if (typeof value === 'string') {
      return findCountryByNameTest(value, countries);
    }
    return value;
  }, [value, countries]);

  // Obtenir les options uniques et triées directement ici
  const uniqueSortedCountries = useMemo(() => {
    // Utiliser un Map pour éliminer les doublons
    const countryMap = new Map(countries.map((country: any) => [country.country_name, country]));

    // Convertir les valeurs du Map en tableau, trier par nom de pays et retourner le tableau trié
    return Array.from(countryMap.values())
      .sort((a: any, b: any) => a.country_name.localeCompare(b.country_name));
  }, [countries]);

  return (
    <Box sx={{ width: '100%', '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { padding: 1.3 } }}>
      <Autocomplete
        options={uniqueSortedCountries}
        disableClearable
        getOptionLabel={(option) => option.country_name}  // Retourner le nom du pays comme chaîne de caractères
        value={initialValue}
        onChange={handleChange}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Iconify sx={{ mr: 1 }} icon={`circle-flags:${findCountryByName(option?.country_name, uniqueSortedCountries)}`} />
            {option?.country_name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: params?.inputProps?.value ? (
                <InputAdornment position="start">
                  <Iconify icon={`circle-flags:${findCountryByName(params?.inputProps?.value, uniqueSortedCountries)}`} />
                </InputAdornment>
              ) : null,
            }}
          />
        )}
        size="small"
      />
    </Box>
  );
};

const KeywordCell = (params: GridRenderCellParams) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isDuplicate = params.row.isDuplicate;

  const keyword = params.row.keyword;
  const country = params.row.country;

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Chip
          size="small"
          label={
            params.row.isDuplicate
              ? `🚫 ${params.row.keyword}`
              : params.row.keyword
          }
          style={{ margin: 2 }}
        />

      </div>
      {isDuplicate && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            width: 700,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1 }}>
            <Typography>Unable to add the following keyword: {keyword}.</Typography>
            <Typography fontSize={14} sx={{ mt: 1 }}>Live monitoring of the following keyword {keyword} has already been configured for the country '{country}.</Typography>
          </Box>
        </Popover>
      )}
    </>
  );
};

// Create columns
const createColumns = (
  deleteItem: (id: number | string) => void,
  resetItem: (id: number | string) => void,
  countries: any,
  popover: any,
  editedRows: Map<number | string, Partial<GridRowModel>>
): GridColDef[] => [
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu params={params} editedRow={editedRows.has(params.id)} deleteItem={deleteItem} resetItem={resetItem} />
      ),
    },
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
      editable: true,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => <KeywordCell {...params} />,
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: true,
      flex: 1,
      filterable: true,
      type: 'singleSelect',
      valueOptions: getUniqueAndSortedCountries(countries),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box display="flex" alignItems="center">
            <Iconify sx={{ mr: 1 }} icon={`circle-flags:${findCountryByName(params.row.country, countries)}`} />
            {params.row.country}
          </Box>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CountryEditInputCell {...params} countries={countries} />;
      },
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['5 min', '10 min', '30 min', '60 min', 'daily'],
      flex: 1,
    },
    {
      field: 'device',
      headerName: 'Device',
      flex: 1,
      editable: true,
      type: 'custom',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {params.value.join(', ')}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      type: 'boolean',
      flex: 1,
    },
  ];

// Action menu component
type ActionMenuProps = {
  params: GridRenderCellParams;
  editedRow: boolean;
  deleteItem: (id: number | string) => void;
  resetItem: (id: number | string) => void;
};

const ActionMenu = ({ params, deleteItem, resetItem, editedRow }: ActionMenuProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(params.row.id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    resetItem(params.row.id);
  };

  return (
    <div>
      <IconButton
        onClick={handleReset}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        disabled={!editedRow}
      >
        <Iconify sx={{ opacity: !editedRow ? 0.2 : 1 }} icon="material-symbols:refresh" />
      </IconButton>

      <IconButton
        onClick={handleDelete}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    </div>
  );
};

type Props = {
  data: DataRow[];
  pageSize: number;
  deleteItem?: (ids: (number | string)[]) => void;
  handleSave: (editedRows: { id: string | number; changes: Partial<GridRowModel> }[]) => void;
  onEdit: (hasEditedRows: boolean) => void;
  onApply: boolean;
  onReset: boolean;
};

export default function DataGridCustomKeywordsScrap({
  data,
  pageSize,
  deleteItem,
  handleSave,
  onEdit,
  onApply,
  onReset,
}: Props) {
  const { data: countriesData }: any = useGetCountriesQuery();

  const countries = countriesData && Array.isArray(countriesData.countries) ? countriesData.countries : [];

  const [rows, setRows] = useState<DataRow[]>(data);
  const [initialRows] = useState<Map<number | string, DataRow>>(new Map(data.map(row => [row.id, { ...row }])));
  const [selectionModel, setSelectionModel] = useState<(number | string)[]>([]);
  const [editedRows, setEditedRows] = useState<Map<number | string, Partial<GridRowModel>>>(new Map());
  const [deletedRowIds, setDeletedRowIds] = useState<(number | string)[]>([]);
  const popover = usePopover();
  const [pendingChanges, setPendingChanges] = useState<Map<number | string, Partial<DataRow>>>(new Map());

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = useCallback((newModel: any) => {
    setPaginationModel(newModel);
  }, []);

  const [scrapingExist] = useScrapingExistMutation();
  const workspace: any = useCurrentWorkspace();

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      const initialRow: any = initialRows.get(newRow.id);
      const isModified = Object.keys(newRow).some((key) => newRow[key] !== initialRow?.[key]);

      // Vérifiez si `keyword` ou `country` ont changé
      const keywordChanged = newRow.keyword !== initialRow?.keyword;
      const countryChanged = newRow.country !== initialRow?.country;

      let isDuplicate = false;

      if (keywordChanged || countryChanged) {
        // Vérifiez les duplications en utilisant les modifications non sauvegardées
        const allPendingChanges = Array.from(pendingChanges.values());

        // Excluez la ligne actuelle des vérifications de duplications
        const potentialDuplicates = allPendingChanges.filter(
          row => row.id !== newRow.id && row.keyword === newRow.keyword && row.country === newRow.country
        );

        if (potentialDuplicates.length > 0) {
          isDuplicate = true;
        } else {
          // Effectuez l'appel API si nécessaire
          try {
            const response: any = await scrapingExist({
              keywords: [newRow.keyword],
              country: newRow.country,
              workspaceId: workspace?.id,
            }).unwrap();

            // Déterminez si la réponse indique un doublon
            isDuplicate = response.isDuplicate;
          } catch (error) {
            isDuplicate = true;
          }
        }
      }

      const updatedRow = {
        ...newRow,
        isDuplicate
      };

      // Mettez à jour les lignes et les modifications non sauvegardées
      setRows((prevRows: any) => prevRows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
      setPendingChanges((prev) => {
        const updated = new Map(prev);
        updated.set(newRow.id, updatedRow);
        return updated;
      });

      if (isModified) {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(newRow.id, updatedRow);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      } else {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(newRow.id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }

      return updatedRow;
    },
    [initialRows, pendingChanges, scrapingExist, workspace, handleSave, onEdit]
  );


  const handleDeleteItem = useCallback(
    (id: number | string) => {
      // Trouver la ligne à supprimer par ID
      const rowToDelete = rows.find((row) => row.id === id);

      if (rowToDelete) {
        // Mettre à jour uniquement la ligne spécifique à supprimer
        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, isDeleted: true } : row
        );

        // Mettre à jour l'état des lignes
        setRows(updatedRows);
        setDeletedRowIds((prev) => [...prev, id]);

        // Mettre à jour les lignes éditées
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(id, { ...rowToDelete, isDeleted: true });
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [rows, handleSave, onEdit]
  );

  const handleResetItem = useCallback(
    (id: number | string) => {
      const originalRow = data.find((row) => row.id === id);
      if (originalRow) {
        setRows((prevRows) => prevRows.map((row) => (row.id === id ? originalRow : row)));
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [data, handleSave, onEdit]
  );

  const handleReset = useCallback(() => {
    setRows([...data]);
    setEditedRows(new Map());
    setSelectionModel([]);
    setDeletedRowIds([]);
    onEdit(false);
  }, [data, onEdit]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset, handleReset]);

  useEffect(() => {
    if (!onApply) {
      setEditedRows(new Map());
    }
  }, [onApply]);

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  // Get class names for rows and cells
  const getRowClassName = (params: any) => {
    const classes: string[] = [];
    if (params.row.isDeleted) {
      classes.push('deleted');
    } else if (params.row.status === false) {
      classes.push('status-false');
    }
    if (params.row.isDuplicate) {
      classes.push('duplicate'); // Add class for duplicates
    }
    if (editedRows.has(params.id)) {
      classes.push('edited');
    }
    return classes.join(' ');
  };

  const getCellClassName = (params: any) => {
    if (params.row.status === false && ['keyword', 'country', 'frequency', 'device'].includes(params.field)) {
      return 'cell-opacity';
    }
    return '';
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row.edited': {
          backgroundColor: '#666546',
          '&:hover': {
            backgroundColor: '#666546 !important',
          },
        },
        '& .MuiDataGrid-row.deleted': {
          backgroundColor: '#4c3333',
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-row.edited.status-false': {
          backgroundColor: '#666546', /* Override with edited color */
        },
        '& .MuiDataGrid-row.duplicate': {
          backgroundColor: '#4c3333!important', /* Background color for duplicate rows */
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-cell.cell-opacity': {
          opacity: 0.3,
        },
      }}
    >
      <DataGridPro
        disableRowSelectionOnClick
        rows={rows}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(handleDeleteItem, handleResetItem, countries, popover, editedRows)} // Assurez-vous que les arguments sont corrects        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        disableDensitySelector
        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel as (number | string)[])}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: GridToolbar
        }}
      />
    </Box>
  );
}
