// components
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[]; // Ajout de la prop labels
};

export default function ChartColumnStackedPercent({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: true,
      type: 'bar',
      stackType: '100%',
    },
    series: series,
    fill: {
      colors: series?.length === 3
        ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
        : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    },
    colors: series?.length === 3
      ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
      : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    labels: labels || [],
    legend: {
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      offsetY: 20,
    },
    plotOptions: {
      bar: {
        barHeight: '80%',
        columnWidth: '70%', // Réduire légèrement la largeur des colonnes
        borderRadius: 0,
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    stroke: {
      width: 0, // Désactiver les bordures des barres
      show: false,
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getFullYear();
      //       const month = String(date.getMonth() + 1).padStart(2, '0');
      //       const day = String(date.getDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return `${val} %`;
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number, { dataPointIndex, w }) => {
          const total = w.globals.stackedSeriesTotals[dataPointIndex];
          const percentage = total ? ((val / total) * 100).toFixed(2) : 0;
          return `${val.toFixed(0)} (${percentage}%)`;
        },
      },
    }
  });

  return <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={350} />;
}
