import { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import Iconify from '../iconify';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import { useLocales } from "src/locales";
import { format, parseISO } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';


type Props = {
  data: {
    id: string;
    role: string;
    email: string;
    profile: {
      firstname: string;
      lastname: string;
      last_login_at: Date;
    }
  }[];
  deleteItem: (id: string) => void;
  editItem: (id: string) => void;
};

const ActionMenu = ({ params, deleteItem, editItem }: { params: any, deleteItem: (id: string) => void, editItem: (id: string) => void }) => {
  const { t } = useLocales();


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    editItem(params?.row?.id);
    handleClose();
  };

  const handleDelete = () => {
    deleteItem(params?.row?.id);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <Iconify sx={{ mr: 1 }} icon="solar:pen-bold" />
          {t('dashboard.global.edit')}
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify sx={{ mr: 1 }} icon="solar:trash-bin-trash-bold" />
          {t('dashboard.global.delete')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default function DataGridCustom({ data, deleteItem, editItem }: Props) {
  const { currentLang, t } = useLocales();

  const createColumns = (deleteItem: (id: string) => void, editItem: (id: string) => void): GridColDef[] => [
    {
      field: 'id',
      headerName: "id",
      flex: 1,
    },
    {
      field: 'firstname',
      headerName: t('dashboard.global.firstname'),
      flex: 1,
      renderCell: (params) => (
        <>{params?.row?.profile?.firstname}</>
      ),
    },
    {
      field: 'lastname',
      headerName: t('dashboard.global.lastname'),
      flex: 1,
      renderCell: (params) => (
        <>{params?.row?.profile?.lastname}</>
      ),
    },
    {
      field: 'email',
      headerName: t('dashboard.global.email'),
      flex: 1,
    },
    {
      field: 'role',
      headerName: t('dashboard.global.role'),
      flex: 1,
    },
    {
      field: 'profile',
      headerName: "Last login at",
      type: "dateTime",
      sortable: true,
      valueGetter: (params: any) => {
        const dateString = params?.last_login_at || params?.created_at;
        return dateString ? parseISO(dateString) : null;
      },

      flex: 1,

      renderCell: (params) => {
        const formatDate = (dateString: any, locale: any) => {
          if (!dateString) return '-';
          const date = new Date(dateString);
          return format(date, 'PPpp', { locale });
        };
        const locale = currentLang?.value;
        const dateToDisplay = params?.row?.profile?.last_login_at || params?.row?.profile?.created_at;
        const formattedDate = formatDate(dateToDisplay, locale === 'fr' ? fr : enUS);
        return <>{!formattedDate ? "-" : formattedDate}</>;
      },

    },
    {
      field: 'action',
      headerName: ' ',
      align: 'right',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => <ActionMenu params={params} deleteItem={deleteItem} editItem={editItem} />,
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };


  return (
    <DataGridPro
      autoHeight
      checkboxSelection={false}
      disableRowSelectionOnClick
      rows={data}
      pagination
      paginationModel={paginationModel}
      columns={createColumns(deleteItem, editItem)}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
      onPaginationModelChange={handlePaginationModelChange}
      disableDensitySelector
      slots={{
        toolbar: GridToolbar,
      }}
    />
  );
}