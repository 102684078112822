import { Route, Routes } from 'react-router';
import Signin from './signin';
import Redirect from './redirect';
import ForgotPassword from './forgot-password';
import UpdatePassword from './update-password';
import ResetPassword from './reset-password';

export default function Auth() {
  return (
      <Routes>
        <Route path="/auth/signin" element={<Signin />} />
        <Route path="/auth/forgot" element={<ForgotPassword />} />
        <Route path="/auth/password" element={<UpdatePassword />} />
        <Route path="/auth/reset_password" element={<ResetPassword />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
  );
}
