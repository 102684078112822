import { useMemo, useState } from 'react';
import { PresetValues } from 'src/context/reducers/attribution-settings/state';

const now = new Date(Date.now());
const quarter = Math.floor(now.getMonth() / 3);

const getInitialObject = () => {
  return { start: new Date(now.getTime()), end: new Date(now.getTime()) };
};

export const presetValues: PresetValues = {
  this_month: { ...getInitialObject(), displayName: 'This month', step: 'date' },
  last_month: { ...getInitialObject(), displayName: 'Last month', step: 'date' },
  last_7d: { ...getInitialObject(), displayName: 'Last 7 days', step: 'date' },
  last_14d: { ...getInitialObject(), displayName: 'Last 14 days', step: 'date' },
  last_30d: { ...getInitialObject(), displayName: 'Last 30 days', step: 'date' },
  last_90d: { ...getInitialObject(), displayName: 'Last 90 days', step: 'date' },
  this_year: { ...getInitialObject(), displayName: 'This year', step: 'year_month' },
};

// Basics
presetValues.last_7d.start.setDate(now.getDate() - 7);
presetValues.last_14d.start.setDate(now.getDate() - 14);
presetValues.last_30d.start.setDate(now.getDate() - 30);
presetValues.last_90d.start.setDate(now.getDate() - 90);

// this month
presetValues.this_month.start.setDate(1);
presetValues.this_month.end.setMonth(now.getMonth() + 1);
presetValues.this_month.end.setDate(0);
presetValues.last_month.start.setMonth(now.getMonth() - 1);
presetValues.last_month.start.setDate(1);
presetValues.last_month.end.setDate(0);

// full year
presetValues.this_year.start.setDate(1);
presetValues.this_year.start.setMonth(0);
presetValues.this_year.end.setMonth(0);
presetValues.this_year.end.setDate(0);
presetValues.this_year.end.setFullYear(presetValues.this_year.start.getFullYear());

export default function useDatePreset<T extends keyof PresetValues>(_preset: T) {
  const [preset, setPreset] = useState(_preset);
  const presetvalue = useMemo(() => presetValues[preset], [preset])
  return {
    ...presetvalue,
    setPreset,
    preset,
  };
}
