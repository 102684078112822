import { useState, useCallback } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import ProfileCover from '../profile-cover';
import { useCurrentUserQuery } from 'src/context/api/auth';
import { SplashScreen } from 'src/components/loading-screen';
import AccountGeneralSettings from '../profile-settings';
import AccountGeneral from '../profile-settings-tab';
import AccountSecurity from '../profile-security';
import { Box } from '@mui/material';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function UserProfileView() {
    const settings = useSettingsContext();
    const { t } = useLocales();
    const { data: user, ...currentUserQuery } = useCurrentUserQuery();

    const [searchFriends, setSearchFriends] = useState('');

    const [currentTab, setCurrentTab] = useState('general');

    const TABS = [
        {
            value: 'general',
            label: t('dashboard.global.general'),
            icon: <Iconify icon="solar:user-id-bold" width={24} />,
            disabled: false,
        },
        // {
        //     value: 'billing',
        //     label: 'Billing',
        //     icon: <Iconify icon="solar:bill-list-bold" width={24} />,
        //     disabled: true,
        // },
        // {
        //     value: 'security',
        //     label: 'Security',
        //     icon: <Iconify icon="solar:shield-check-bold" width={24} />,
        //     disabled: false,
        // },
    ];

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }, []);

    const handleSearchFriends = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFriends(event.target.value);
    }, []);

    if (currentUserQuery.isLoading) {
        return <SplashScreen />;
    }

    if (user) {
        return (
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                <CustomBreadcrumbs
                    heading={t('dashboard.account.profile')}
                    links={[
                        { name: t('dashboard.account.user') },
                        { name: user?.profile?.firstname ? `${user?.profile?.firstname} ${user?.profile?.lastname}` : user?.email },
                    ]}
                    sx={{
                        mb: { xs: 3, md: 5 },
                    }}
                />

                <Card
                    sx={{
                        mb: 3,
                        height: 290,
                    }}
                >
                    <ProfileCover
                        id={user.id}
                        username={user.username}
                        email={user.email}
                        profile={user?.profile}
                        coverUrl={"/assets/covers/cover-4.webp"}
                    />

                    <Tabs
                        value={currentTab}
                        onChange={handleChangeTab}
                        sx={{
                            width: 1,
                            bottom: 0,
                            zIndex: 9,
                            position: 'absolute',
                            bgcolor: 'background.paper',
                            [`& .${tabsClasses.flexContainer}`]: {
                                pr: { md: 3 },
                                justifyContent: {
                                    sm: 'center',
                                    md: 'flex-end',
                                },
                            },
                        }}
                    >
                        {TABS.map((tab) => (
                            <Tab disabled={tab.disabled} key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                        ))}
                    </Tabs>
                </Card>

                <AccountGeneral />
                
                <Box sx={{pt: 3}}>
                    <AccountSecurity />
                </Box>

            </Container>
        );
    }
}
