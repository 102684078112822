import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const liveBidding = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.liveBidding',
  tagTypes: ['liveBidding'],
  endpoints: (builder) => ({
    getActiveMode: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/active-mode',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    getKoBidding: builder.query<any, { workspaceId: any | null }>({
      query: (arg) => ({
        url: '/liveBidding/ko-bidding',
        method: 'GET',
        params: {
          workspaceId: arg.workspaceId,
        },
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        }
      }),
    }),
    // Mutation for adding a bidding strategy to active mode
    addActiveMode: builder.mutation<any, Partial<any>>({
      query: (newStrategy) => ({
        url: '/liveBidding/active-mode/add',
        method: 'POST',
        body: newStrategy,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    // Mutation for deleting an active mode strategy
    deleteActiveMode: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/liveBidding/active-mode/delete/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getKeywords: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/keywords/active-mode`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCampaigns: builder.query<any, { client: string; keyword: string }>({
      query: ({ client, keyword }) => ({
        url: `/liveBidding/campaigns/active-mode`,
        method: 'POST',
        body: { client, keyword },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCountries: builder.query<any, { client: string }>({
      query: ({ client }) => ({
        url: `/liveBidding/countries/active-mode`,
        method: 'POST',
        body: { client },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetActiveModeQuery,
  useGetKoBiddingQuery,
  useAddActiveModeMutation,
  useGetKeywordsQuery,
  useGetCampaignsQuery,
  useGetCountriesQuery,
  useDeleteActiveModeMutation
} = liveBidding;

export default liveBidding;
