import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const trafficAnalysis = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.trafficAnalysis',
  tagTypes: ['trafficAnalysis'],
  endpoints: (builder) => ({
    getStatsDash: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null }>({
      query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId }) => {
        const url = `/trafficAnalysis/getStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          timestep,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getKeywords: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], workspaceId: any }>({
      query: ({ startDate, endDate, device, countries, workspaceId }) => {
        const url = `/trafficAnalysis/getKeywords`;
        const body = {
          startDate,
          endDate,
          device,
          countries,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountries: builder.query<any, { startDate: string | null, endDate: string | null, device: string[] | null, keywords: string[], workspaceId: any }>({
      query: ({ startDate, endDate, device, keywords, workspaceId }) => {
        const url = `/trafficAnalysis/getCountries`;
        const body = {
          startDate,
          endDate,
          device,
          keywords,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getDevices: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], workspaceId: any }>({
      query: ({ startDate, endDate, countries, keywords, workspaceId }) => {
        const url = `/trafficAnalysis/getDevices`;
        const body = {
          startDate,
          endDate,
          keywords,
          countries,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useGetStatsDashQuery,
  useGetCountriesQuery,
  useGetDevicesQuery,
  useGetKeywordsQuery,
} = trafficAnalysis;

export default trafficAnalysis;
