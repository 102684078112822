import React from 'react';
import { useSelector } from 'react-redux';
import Chart, { useChart } from 'src/components/chart';

const StackedBarChartWithLine = ({ series, labels }: any) => {
  // Diviser les séries en barres et lignes
  const barSeries = series.filter((item: any) => item.type === 'bar');
  const lineSeries = series.filter((item: any) => item.type === 'line');

  const hasCurrencySeries = series.some((item: any) => item.format === 'usd');
  const hasNumberSeries = series.some((item: any) => item.format === 'number');
  const filterStore = useSelector((state: any) => state.filters);

  const stackedColors = [
    '#AD1457', '#C2185B', '#D81B60', '#E91E63',
    '#EC407A', '#F06292', '#F48FB1', '#F8BBD0'
  ];

  const complementaryColors = [
    '#009688', '#FF5722', '#3F51B5', '#00BCD4',
    '#795548', '#607D8B', '#9E9E9E'
  ];

  const getColor = (index: any, isStacked: any) => {
    if (isStacked) {
      return stackedColors[index % stackedColors.length];
    } else {
      return complementaryColors[index % complementaryColors.length];
    }
  };

  // Configuration des axes Y
  const yaxis = [];

  if (hasNumberSeries) {
    yaxis.push({
      title: {
        text: 'Number',
      },
      labels: {
        formatter: function (value: any) {
          return value !== undefined ? value.toFixed(1) : "";
        },
      },
      min: 0,
      opposite: false, // Axe de gauche
    });
  }

  if (hasCurrencySeries) {
    yaxis.push({
      title: {
        text: 'Currency',
      },
      labels: {
        formatter: function (value: any) {
          return value !== undefined ? `$${value.toFixed(1)}` : "";
        },
      },
      min: 0,
      opposite: true, // Axe de droite
    });
  }

  // Attribution des couleurs et des axes Y
  
  barSeries.forEach((seriesItem: any, index: any) => {
    seriesItem.yAxis = seriesItem.format === 'usd' ? 1 : 0;
    seriesItem.color = getColor(index, true);
  });

  lineSeries.forEach((seriesItem: any, index: any) => {
    seriesItem.yAxis = seriesItem.format === 'usd' ? 1 : 0;
    seriesItem.color = getColor(index, false);
  });

  const chartOptions: any = useChart({
    chart: {
      type: 'line',
      stacked: true,
    },
    stroke: {
      width: 2,
      show: true,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
        },
        borderRadius: 0,
        barHeight: '70%',
      },
    },
    labels,
    series:[
      ...barSeries,
      ...lineSeries.map((item: any) => ({
        ...item,
        type: 'line',
      })),
    ],
    xaxis: {
      type: 'category',
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val: any) => {
      //       const date = new Date(val);
      //       const year = date.getFullYear();
      //       const month = String(date.getMonth() + 1).padStart(2, '0');
      //       const day = String(date.getDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    markers: {
      size: lineSeries.map(() => 6),
    },
    yaxis,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number, { seriesIndex, w }) => {
          const seriesFormat = w.config.series[seriesIndex]?.format;
          return seriesFormat === 'usd' ? `$${value.toFixed(1)}` : `${value.toFixed(1)}`;
        },
      },
    },
    legend: {
      position: 'top',
    },
    colors: [
      ...barSeries.map((_: any, index: any) => getColor(index, true)),
      ...lineSeries.map((_: any, index: any) => getColor(index, false)),
    ],
  });

  // Combinaison des séries barres et lignes
  const combinedSeries = [
    ...barSeries,
    ...lineSeries.map((item: any) => ({
      ...item,
      type: 'line',
    })),
  ];

  return <Chart options={chartOptions} series={combinedSeries} type="line" height={350} />;
};

export default StackedBarChartWithLine;
