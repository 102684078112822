import { Container } from "@mui/system";

export default function AdGroupBased() {


    return (
        <>
            <Container maxWidth="xl" sx={{ marginTop: 1 }}>
                Ad Group Based
            </Container>
        </>
    );
}
