import { ApexOptions } from 'apexcharts';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import Chart, { useChart } from 'src/components/chart';
import { useLocales } from 'src/locales';
import { useSelector } from 'react-redux';
import { fPercent } from 'src/utils/format-number';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    series: {
      categories: string[];
      data: {
        name: string;
        data: number[];
        visible: boolean;
        dashStyle: any;
        color: any;
        yAxis: number; // Explicitement requis maintenant
      }[];
    }[];
    options?: ApexOptions;
  };
}

export default function AppAreaInstalled({ title, subheader, chart, ...other }: Props) {
  const { series, options } = chart;
  const { t } = useLocales();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()

  // Extraire les séries visibles
  const visibleSeries = series[0].data.filter((seriesItem) => seriesItem.visible);
  const colors = visibleSeries.map((seriesItem) => seriesItem.color);

  // Générer les axes Y pour chaque série
  const yaxis = visibleSeries.map((seriesItem, index) => ({
    seriesName: seriesItem.name,
    axisTicks: { show: false },
    axisBorder: { show: false },
    labels: { show: false },
    title: { text: undefined }, // Pas de titre
    opposite: index % 2 === 0,
  }));


  const formatCurrency = (number: any, options: any, isAdCost = false) => {
    const lang: any = localStorage.getItem('i18nextLng');
    const currencySymbol = workspace?.currency === "usd" ? '$' : '€';

    let maximumFractionDigits = 2; // Valeur par défaut
    if (number >= 1000000) {
      maximumFractionDigits = 1; // Moins de décimales pour des millions
    } else if (number >= 10000) {
      maximumFractionDigits = 1; // Moins de décimales pour des milliers
    } else if (number < 1) {
      maximumFractionDigits = 4; // Plus de décimales pour des valeurs très petites
    }

    options = { ...options, maximumFractionDigits }; // Appliquer les décimales calculées dynamiquement

    let formattedNumber;
    if (isAdCost) {
      if (number >= 1000000) {
        formattedNumber = (number / 1000000).toLocaleString(lang, { maximumFractionDigits }) + 'M';
      } else if (number >= 10000) {
        formattedNumber = (number / 1000).toLocaleString(lang, { maximumFractionDigits }) + 'K';
      } else {
        formattedNumber = number.toLocaleString(lang, options);
      }
    } else if (number >= 1000000) {
      formattedNumber = (number / 1000000).toLocaleString(lang, { maximumFractionDigits }) + 'M';
    } else if (number >= 10000) {
      formattedNumber = (number / 1000).toLocaleString(lang, { maximumFractionDigits }) + 'K';
    } else {
      formattedNumber = number.toLocaleString(lang, options);
    }

    if (lang === 'fr') {
      formattedNumber = formattedNumber.replace('.', ',');
    }

    return currencySymbol + formattedNumber;
  };
  const chartOptions = useChart({
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: ['#FFFFFF'],
          fontSize: '12px',
        },
        offsetX: 0, // Adjust the offset
      },
      categories: series[0]?.categories || [],
    },
    yaxis: yaxis,
    fill: {
      colors: colors,
    },
    colors: colors,
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, w }) => {
          const seriesName = w.config.series[seriesIndex].name;
          if (seriesName === t('dashboard.global.blended_ctr')) {
            return fPercent(value);
          }
          else if (seriesName === t('dashboard.global.ad_cost')) {
            return formatCurrency(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
          } else if (seriesName === "Ad Cost Compare") {
            return formatCurrency(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
          }
          return value.toFixed(0).toString();
        },
      },
    },
    stroke: {
      width: 2,
      dashArray: visibleSeries.map((seriesItem) => (seriesItem.dashStyle === "ShortDash" ? 4 : 0)),
    },
    ...options,
  });

  return (
    <Card {...other} sx={{ borderRadius: 0, borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ ml: 4 }}>
        <Chart
          dir="ltr"
          type="line"
          series={visibleSeries.map((seriesItem) => ({
            name: seriesItem.name,
            data: seriesItem.data,
            yAxisIndex: seriesItem.yAxis,
          }))}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
