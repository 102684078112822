import { Box, Button, Dialog, Paper, Skeleton, Typography, useTheme, DialogTitle, DialogContent, DialogActions, Autocomplete, Chip, TextField, Slider, InputAdornment, Alert } from "@mui/material";
import DataGridCustomKeywordsScrap from "src/components/data-grid/data-grid-custom-keywords-scraping";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { useState, useCallback, useEffect } from "react";
import { ComponentBlock } from "src/sections/mui/component-block";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { useAddKeywordScrapingMutation, useDeleteScrapingMutation, useGetScrapingQuery, useScrapingExistMutation, useUpdateScrapingMutation } from "src/context/api/admin/api";
import { enqueueSnackbar } from "notistack";
import { GridRowModel } from "@mui/x-data-grid";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ComponentBlockCustom } from "src/sections/mui/component-block-custom";
import { useLocation } from "react-router";
import { useGetCountriesQuery } from "src/context/api/countries/api";

export default function AdminScrapping() {
    const theme = useTheme();
    const keywordDialog = useBoolean(false);
    const [inputValue, setInputValue] = useState('');
    const workspace: any = useCurrentWorkspace();
    const [addKeywordScraping] = useAddKeywordScrapingMutation();
    const [updateScraping] = useUpdateScrapingMutation();
    const [deleteScraping] = useDeleteScrapingMutation();
    const [isEdited, setIsEdited] = useState(false);
    const [keywordCount, setKeywordCount]: any = useState([]);
    const [editedRows, setEditedRows] = useState<{ id: string | number, changes: Partial<GridRowModel> }[]>([]);
    const [isReset, setIsReset] = useState(false);
    const location = useLocation();
    const { data: countriesData } = useGetCountriesQuery();
    const [scrapingExist] = useScrapingExistMutation();

    const [titleScrapExist, setTitleScrapExist] = useState('')
    const [descriptionScrapExist, setDescriptionScrapExist] = useState('')

    const defaultCountry = {
        country_code: 'us',
        country_language: 'en',
        country_name: 'United States',
    };

    const schema = yup.object().shape({
        keywords: yup.array().of(yup.string().required("Keyword is required")).min(1, "At least one keyword is required"),
        selectedCountry: yup.object().required("Country is required"),
        frequency: yup.string().required("Frequency is required"),
        device: yup.array().of(yup.string().required()).min(1, "At least one device is required"),
    });

    const { data: dataScraping, error: dataScrapingError, isFetching: isFetchingDataScraping, refetch } = useGetScrapingQuery({
        workspaceId: workspace?.id,
    });

    const dataScrap = dataScraping?.map((item: any) => ({
        id: item?.id,
        keyword: item?.keyword,
        country: item?.country,
        frequency: item?.frequency,
        device: item?.device,
        status: item?.status
    }));

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            keywords: [],
            selectedCountry: defaultCountry,
            frequency: '10 min',
            device: ['desktop']
        }
    });

    const marks = [
        { value: 5, label: '5 min' },
        { value: 10, label: '10 min' },
        { value: 30, label: '30 min' },
        { value: 60, label: '60 min' },
        { value: 100, label: 'daily' }
    ];

    const handleAddKeywords = async (data: any) => {
        try {
            const keywordsData = data.keywords.map((keyword: string) => ({
                keyword,
                country: data.selectedCountry?.country_name,
                country_code: data.selectedCountry?.country_code,
                frequency: data.frequency,
                device: data.device,
                workspaceId: workspace?.id,
                client: workspace?.bqTable,
                mode: "Scrapping"
            }));

            // Récupérer tous les mots-clés pour la vérification
            const keywordsToCheck = keywordsData.map((k: any) => k.keyword);

            const response: any = await scrapingExist({
                keywords: keywordsToCheck,  // Passez les mots-clés sous forme de liste
                country: data.selectedCountry?.country_name,
                workspaceId: workspace?.id,
            }).unwrap();

            if (response.exists) {
                return; // Arrêter le processus si des mots-clés existent déjà
            }

            // Si aucun duplicata, procéder à l'ajout des mots-clés
            await Promise.all(keywordsData.map((keywordData: any) => addKeywordScraping(keywordData).unwrap()));

            enqueueSnackbar("Added successfully", { variant: 'success' });
            reset();
            setKeywordCount([]);
            keywordDialog.onFalse();
            refetch();
        } catch (error: any) {
            setTitleScrapExist(error?.data?.title);
            setDescriptionScrapExist(error?.data?.description);

            enqueueSnackbar("Failed to add", { variant: 'error' });
        }
    };

    const handleSave = useCallback(async () => {
        try {
            const updates: any = editedRows.map(({ id, changes }) => {
                // Extraire les informations du pays si elles sont présentes
                const { ...otherChanges } = changes;

                const updateData = {
                    id,
                    ...otherChanges,
                };

                return updateData;
            });

            // Filtrer les IDs des doublons et des éléments supprimés
            const duplicateIds = updates
                .filter((item: any) => item.isDuplicate === true)
                .map((item: any) => item.id);

            const deletedIds: any = updates
                .filter((item: any) => item.isDeleted === true)
                .map((item: any) => item.id);

            // Supprimer les doublons
            if (duplicateIds.length > 0) {
                await Promise.all(
                    duplicateIds.map((id: any) => deleteScraping({ scraping_id: id }).unwrap())
                );
                enqueueSnackbar("Duplicates removed successfully", { variant: 'success' });
            }

            // Supprimer les éléments marqués comme supprimés
            if (deletedIds.length > 0) {
                await Promise.all(
                    deletedIds.map((id: any) => deleteScraping({ scraping_id: id }).unwrap())
                );
                enqueueSnackbar("Items removed successfully", { variant: 'success' });
            }

            // Mettre à jour les éléments non supprimés ou non doublons
            if (updates.length > 0) {
                await updateScraping({ updates }).unwrap();
                enqueueSnackbar("Edited successfully", { variant: 'success' });
            }

            // Mettre à jour l'état local après la suppression et mise à jour
            refetch();  // Recharger les données après les mises à jour
            setIsEdited(false);
            setEditedRows([]);
        } catch (error) {
            enqueueSnackbar("Failed to edit", { variant: 'error' });
        }
    }, [editedRows, updateScraping, deleteScraping, refetch]);

    const toggleDevice = (selectedDevice: string, devices: string[]) => {
        const currentIndex = devices.indexOf(selectedDevice);
        const newDevices = [...devices];

        if (currentIndex === -1) {
            newDevices.push(selectedDevice);
        } else {
            newDevices.splice(currentIndex, 1);
        }

        return newDevices;
    };

    const handleEditedRowsChange = useCallback((newEditedRows: any) => {
        setEditedRows(newEditedRows);
        setIsEdited(newEditedRows.length > 0);
    }, []);

    const handleDiscard = () => {
        setIsReset(true);
        setEditedRows([]);
        setIsEdited(false);
        setTimeout(() => setIsReset(false), 0);
    };

    useEffect(() => {
        if (location?.pathname?.split("/")[4] === "add") {
            keywordDialog.onTrue()
        }
    }, [location])

    const sortedUniqueCountries = Array.from(new Map(countriesData?.countries.map((country: any) => [country.country_name, country])).values())
        .sort((a: any, b: any) => a.country_name.localeCompare(b.country_name));

    const filterOptions = (options: any, { inputValue }: any) => {
        const filtered = options.filter((option: any) =>
            option.country_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filtered;
    };

    return (
        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
            <Paper
                sx={{
                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                    height: 'auto',
                    borderRadius: 2,
                    marginBottom: 1
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10, marginBottom: 35 }}>
                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25, marginBottom: '5px' }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Live Branded Keywords Monitoring
                        </Typography>

                        <Typography variant="overline" color="textSecondary" sx={{ textTransform: 'none', fontSize: 14 }}>
                            List of branded keyword SERPs monitored in real time by Cross-Brand live scraping feature
                        </Typography>
                    </div>
                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => keywordDialog.onTrue()}
                            disabled={editedRows?.length !== 0 && true}
                            startIcon={<Iconify icon="mingcute:add-line" />}>
                            Add keyword(s)
                        </Button>

                        {editedRows?.length !== 0 &&
                            <>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleDiscard}
                                    startIcon={<Iconify icon="material-symbols:refresh" />}
                                    sx={{ ml: 1, backgroundColor: '#232b35', color: 'white' }}
                                >
                                    Discard All Changes
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleSave}
                                    sx={{ ml: 1, backgroundColor: "#4b1db0", color: 'white' }}
                                    startIcon={<Iconify icon="tabler:check" />}
                                >
                                    Apply All Changes
                                </Button>
                            </>
                        }

                    </div>
                </div>

                {isFetchingDataScraping || (!dataScraping && !dataScrapingError)
                    ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                        <>
                            <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                            <Typography color={"success.main"}>Active</Typography>
                        </>
                    </Skeleton>
                    : <DataGridCustomKeywordsScrap
                        pageSize={20}
                        onEdit={(it: any) => setIsEdited(it)}
                        handleSave={handleEditedRowsChange}
                        data={dataScrap}
                        onApply={isEdited}
                        onReset={isReset}
                    />
                }
            </Paper>

            <Dialog
                open={keywordDialog.value}
                onClose={keywordDialog.onFalse}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Scrap New Keyword(s)</DialogTitle>
                <DialogContent>

                    <ComponentBlock sx={{ marginTop: 2 }} title="Keyword Settings">
                        <Controller
                            name="keywords"
                            control={control}
                            render={({ field }: any) => (
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    sx={{ width: '100%', height: 'auto' }}
                                    options={[]}
                                    value={field.value}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                        setKeywordCount(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' && inputValue.trim() !== '') {
                                            const updatedValue = [...field.value, inputValue.trim()];
                                            setKeywordCount(updatedValue);
                                            event.preventDefault();
                                            field.onChange(updatedValue);
                                            setInputValue('');
                                        }
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Keyword(s) to scrape"
                                            placeholder="Enter keyword and press Enter"
                                            error={!!errors.keywords}
                                            helperText={errors.keywords ? errors.keywords.message : ''}
                                        />
                                    )}
                                />
                            )}
                        />
                    </ComponentBlock>

                    <ComponentBlock sx={{ marginTop: 5, marginBottom: 1 }} title="Scraping Settings">
                        <Box sx={{ width: '100%' }}>
                            <Controller
                                name="selectedCountry"
                                control={control}
                                render={({ field }: any) => (
                                    <Autocomplete
                                        options={sortedUniqueCountries}
                                        getOptionLabel={(option: any) => option?.country_name}
                                        filterOptions={filterOptions}
                                        renderOption={(props: any, option: any) => (
                                            <Box component="li" {...props}>
                                                <Iconify icon={`circle-flags:${option?.country_code}`} style={{ marginRight: 8 }} />
                                                {option?.country_name}
                                            </Box>
                                        )}
                                        value={field.value}
                                        onChange={(event, newValue) => field.onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Scrap search result from"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mb: 3 }}
                                                error={!!errors.selectedCountry}
                                                helperText={errors.selectedCountry ? errors.selectedCountry.message : ''}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: field?.value && (
                                                        <InputAdornment position="start">
                                                            <Iconify icon={`circle-flags:${field?.value?.country_code}`} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />

                            <ComponentBlockCustom sx={{ marginTop: 1 }} title="Scraping Frequency">
                                <Controller
                                    name="frequency"
                                    control={control}
                                    render={({ field }) => (
                                        <Slider
                                            value={marks.find(mark => mark.label === field.value)?.value || 10}
                                            onChange={(_, newValue) => field.onChange(marks.find(mark => mark.value === newValue)?.label)}
                                            step={null}
                                            marks={marks}
                                            min={5}
                                            max={100}
                                            valueLabelDisplay="auto"
                                            sx={{
                                                mb: 3,
                                                '& .MuiSlider-thumb': {
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: '#fff',
                                                    border: '2px solid currentColor',
                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                        boxShadow: 'none',
                                                    },
                                                },
                                                '& .MuiSlider-track': {
                                                    height: 8,
                                                    backgroundColor: '#8339f6'
                                                },
                                                '& .MuiSlider-rail': {
                                                    height: 8,
                                                    opacity: 1,
                                                    backgroundColor: '#313840',
                                                },
                                                '& .MuiSlider-markLabel': {
                                                    transform: 'translateX(-50%)',
                                                    whiteSpace: 'nowrap',
                                                },
                                                '& .MuiSlider-markLabel[data-index="0"]': {
                                                    transform: 'translateX(-1%)',
                                                },
                                                '& .MuiSlider-markLabel[data-index="1"]': {
                                                    transform: 'translateX(-11%)',
                                                },
                                                '& .MuiSlider-markLabel[data-index="2"]': {
                                                    transform: 'translateX(-40%)',
                                                },
                                                '& .MuiSlider-markLabel[data-index="3"]': {
                                                    transform: 'translateX(-85%)',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </ComponentBlockCustom>

                            <ComponentBlockCustom sx={{ marginTop: 3 }} title="Scraping Devices">
                                <Controller
                                    name="device"
                                    control={control}
                                    render={({ field }: any) => (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                                            <Box onClick={() => field.onChange(toggleDevice('desktop', field.value))} sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
                                                <Iconify color={field.value.includes('desktop') ? "#8339f6" : "#9faab7"} fontSize={16} mr={1} icon={"ph:desktop"} />
                                                <Typography fontSize={15} variant="body2">Scrap Desktop Results</Typography>
                                            </Box>
                                            <Box onClick={() => field.onChange(toggleDevice('mobile', field.value))} sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
                                                <Iconify color={field.value.includes('mobile') ? "#8339f6" : "#9faab7"} fontSize={16} mr={1} icon={"clarity:mobile-solid"} />
                                                <Typography fontSize={15} variant="body2">Scrap Mobile Results</Typography>
                                            </Box>
                                        </Box>
                                    )}
                                />
                                {errors.device && (
                                    <Typography color="error" fontSize={"0.75rem"} variant="body2" sx={{ mt: 1 }}>{errors.device.message}</Typography>
                                )}
                            </ComponentBlockCustom>
                        </Box>
                    </ComponentBlock>

                    {(titleScrapExist !== "" && descriptionScrapExist !== "") &&
                        <>
                            <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                                <Typography sx={{ fontWeight: 900 }}>{titleScrapExist}</Typography>
                                <Typography fontSize={14} sx={{ mt: .4 }}>{descriptionScrapExist}</Typography>
                            </Alert>
                        </>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => keywordDialog.onFalse()} variant="text" sx={{ mr: 1, color: 'white' }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit(handleAddKeywords)}>
                        Add keywords ({keywordCount?.length})
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
